import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { ItemBundle, ItemBundleNextNo, ItemBundles, ItemBundleAdd, ItemBundleReduce, ItemBundleDelete } from '@/graphql/inventory/itemBundle.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const itemBundleFetch = ({ itemBundleId, queryType = 'default', noCache = false }: IItemBundleAPI) => {
  const {
    result,
    onResult: onItemBundleResult,
    loading,
    refetch: itemBundleRefetch,
    error,
  } = useQuery(ItemBundle, { id: itemBundleId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const itemBundle = computed(() => result.value?.itemBundle ?? {});
  return { itemBundle, onItemBundleResult, loading, itemBundleRefetch, error };
};

const itemBundleNextNoFetch = ({ organizationId }: IItemBundleAPI) => {
  const { result, onResult: onInvoiceNextNo, loading, error } = useQuery(ItemBundleNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const invoiceNextNo = computed(() => result.value?.itemBundleNextNo ?? []);
  return { documentNextNo: invoiceNextNo, onDocumentNextNo: onInvoiceNextNo, loading, error };
};

const itemBundlesFetch = (variables: IItemBundleAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: itemBundlesRefetch,
    error,
  } = useQuery(ItemBundles, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const itemBundles = computed(() => result.value?.itemBundles ?? []);

  return { itemBundles, loading, itemBundlesRefetch, error };
};

// mutations
const itemBundlePost = ({ data, mutationType = 'add' }: IItemBundleAPI) => {
  const mutationMap: any = { add: ItemBundleAdd, reduce: ItemBundleReduce, delete: ItemBundleDelete };
  const { mutate: postItemBundle, loading, onDone, onError } = useMutation(mutationMap[mutationType], { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Item bundle successfully ${mutationType === 'add' ? 'added' : 'deleted'}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: mutationType === 'delete' ? 'toastAlert' : 'formAlert' }));

  return { postItemBundle, loading };
};

export { itemBundleFetch, itemBundleNextNoFetch, itemBundlesFetch, itemBundlePost };

interface IFilter {
  filterBy: string[];
  search?: string;
}

export interface IItemBundleAPI {
  organizationId?: string;
  itemBundleId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  queryType?: string;
  noCache?: boolean;
  mutationType?: string;
}
