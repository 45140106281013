import wNumb from 'wnumb';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

// constants
export const DateToday = dayjs().format('DD/MM/YYYY');
export const DateTodayISO = dayjs().format();

// eslint-disable-next-line no-restricted-globals
export const ObjectResolve = (path: any, obj: any) => path.split('.').reduce((prev: any, curr: any) => (prev ? prev[curr] : undefined), obj || self);
// @ts-ignore
export const Round = (value: number, decimals: number) => Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);

// TODO: this is not working for nested array of objects - case: SalesDocumentsMixins, lineitems - insufficientStock
export const OmitDeep = (list: any, keys: string[], values: any[] = []) => {
  // tslint:disable-next-line:forin
  for (const key in list) {
    if (keys.includes(key) || values.includes(list[key])) {
      delete list[key];
    } else if (list[key] && typeof list[key] === 'object' && Object.keys(list[key]).length) {
      OmitDeep(list[key], keys, values);
    } else if (Array.isArray(list[key]) && list[key].length) {
      list[key].forEach((arrayItem: any) => {
        OmitDeep(arrayItem, keys, values);
      });
    }
  }

  return list;
};

export const FormatFields = (list: any, { toFloat = [] }: any) => {
  for (const key in list) {
    if (typeof list[key] === 'object') {
      FormatFields(list[key], { toFloat });
    } else if (Array.isArray(list[key])) {
      list[key].forEach((arrayItem: any) => {
        FormatFields(arrayItem, { toFloat });
      });
    } else {
      if (toFloat.includes(key) && list[key]) {
        list[key] = parseFloat(list[key]);
      }
    }
  }

  return list;
};

export const ToTitleCase = (str: string = '') => {
  return str.toLowerCase().replace(/(?:^|\s|-)\S/g, (x: string) => x.toUpperCase());
};

export const ToCamelCase = (str: string = '') => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const ChangeFirstLetterCase = (str: string = '', caseType: string) => {
  let firstLetter = str.charAt(0);
  if (caseType === 'lower') firstLetter = firstLetter.toLowerCase();
  if (caseType === 'upper') firstLetter = firstLetter.toUpperCase();
  return firstLetter + str.slice(1);
};

// TODO: MoneyFormat - add a currency object, define a type for it. Have a option sent to determine if to show prefix or not
export const FormatMoney = ({ value, format, prefixed = false }: any) => {
  const { symbol, mark, thousand, decimals, suffix } = format;
  return wNumb({ ...(prefixed && { prefix: symbol }), mark, thousand, decimals, suffix }).to(parseFloat(value || 0));
};
export const MoneyFormat = (value: any, { prefix, thousand = ',', mark = '.', decimals = 2, suffix }: IMoneyFormat = {}) => {
  return wNumb({ prefix, mark, thousand, decimals, suffix }).to(parseFloat(value || 0));
};

export const DateFormat = (value: string, format: string = '') => {
  switch (format) {
    case 'iso':
      return dayjs(value, 'DD/MM/YYYY').format();
    case 'time':
      return dayjs(value).format('hh:mm A');
    case 'short1':
      return dayjs(value).format('DD MMM YYYY');
    case 'long':
      return dayjs(value).format('MMM Do YYYY');
    case 'longTime':
      // return dayjs(value).format('MMMM Do YYYY, h:mm:ss a');
      // return dayjs(value).format('DD/MM/YYYY, hh:mm A');
      return dayjs(value).format('DD-MM-YYYY HH:mm:ss');
    default:
      return dayjs(value).format('DD/MM/YYYY');
  }
};

export const ArrayRemove = (array: any, item: any) => {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};

export const ArrayMove = (array: any, fromIndex: number, toIndex: number) => {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);

  return array;
};

export const sortObjectsArray = (array: any[] = [], sortBy: string) => {
  return array ? array.slice().sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0)) : [];
};

export const Debounce = (func: any, delay = 400, { leading }: any = {}) => {
  let timerId: any;

  return (...args: any) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};

export const get = (obj: any, path: string, defValue: any) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray: any = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce((prevObj: any, key: string) => prevObj && prevObj[key], obj);
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

export const getRandomNumber = (length: number) => Math.random().toFixed(length).split('.')[1];
export const generateBarcode = (type: string) => {
  const barcodeTypes: { [x: string]: number } = { ean: 13, upc: 12, isbn: 13 };
  return getRandomNumber(barcodeTypes[type]);
};

export interface IMoneyFormat {
  prefix?: string;
  mark?: string;
  thousand?: string;
  decimals?: number;
  suffix?: string;
}
