import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import {
  CreditNotes,
  CreditNote,
  CreditNoteView,
  CreditNoteFull,
  CreditNoteNextNo,
  CreditNoteAdd,
  CreditNoteUpdate,
  CreditNoteDelete,
  CreditNoteApplyCredits,
  CreditNoteReverseCredits,
  CreditNoteRefund,
} from '@/graphql/sales/creditNote.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const creditNoteFetch = ({ creditNoteId, queryType = 'default', noCache = false }: ICreditNoteAPI) => {
  const queryMap: any = { default: CreditNote, full: CreditNoteFull, view: CreditNoteView };
  const {
    result,
    onResult: onCreditNoteResult,
    loading,
    refetch: creditNoteRefetch,
    error,
  } = useQuery(queryMap[queryType], { id: creditNoteId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const creditNote: any = computed(() => result.value?.creditNote ?? {});
  return { creditNote, onCreditNoteResult, creditNoteRefetch, loading, error };
};
const creditNotesFetch = (variables: IFilter, noCache = false) => {
  const {
    result,
    loading,
    refetch: creditNotesRefetch,
    error,
  } = useQuery(CreditNotes, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const creditNotes = computed(() => result.value?.creditNotes ?? {});
  return { creditNotes, creditNotesRefetch, loading, error };
};
const creditNoteNextNoFetch = ({ organizationId }: ICreditNoteAPI) => {
  const { result, onResult: onCreditNoteNextNo, loading, error } = useQuery(CreditNoteNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const creditNoteNextNo = computed(() => result.value?.creditNoteNextNo ?? []);
  return { documentNextNo: creditNoteNextNo, onDocumentNextNo: onCreditNoteNextNo, loading, error };
};

// mutations
const creditNotePost = ({ data, mutationType = 'add', modalNotification = false }: ICreditNoteAPI) => {
  const mutationMap: any = {
    add: { mutation: CreditNoteAdd, message: 'added' },
    update: { mutation: CreditNoteUpdate, message: 'updated' },
    void: { mutation: CreditNoteDelete, message: 'voided' },
    applyCredits: { mutation: CreditNoteApplyCredits, message: 'credited' },
    reverseCredits: { mutation: CreditNoteReverseCredits, message: 'reversed' },
    refund: { mutation: CreditNoteRefund, message: 'refunded' },
    refundDelete: { mutation: CreditNoteRefund, message: 'deleted refund' },
  };
  const { mutate: postCreditNote, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Credit Note successfully ${mutationMap[mutationType].message}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postCreditNote, loading };
};

export { creditNoteFetch, creditNotesFetch, creditNoteNextNoFetch, creditNotePost };

interface IFilter {
  status: string[];
  search?: string;
}
interface ICreditNoteAPI {
  organizationId?: string;
  creditNoteId?: string;
  pager?: IPager;
  filter?: IFilter;
  noCache?: boolean;
  queryType?: string;
  // for post
  data?: any;
  mutationType?: string;
  modalNotification?: boolean;
}
