import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { Invitation, Invitations, InvitationAdd, InvitationResend, InvitationAccept, InvitationDelete } from '@/graphql/core/invitation.gql';
import { handleError } from '@/composables/useErrorHandler';
import eventHub from '@/utils/eventHub';
import { AlertData } from '@/utils/data';
// remove
import Vue from 'vue';

// Queries
const InvitationFetch = ({ invitationId, noCache = false }: IInvitationAPI) => {
  const {
    result,
    onResult: onInvitationResult,
    loading,
    error,
  } = useQuery(Invitation, { id: invitationId }, { clientId: 'core', fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const invitation: any = computed(() => result.value?.invitation ?? {});
  return { invitation, onInvitationResult, loading, error };
};

const InvitationsFetch = (variables: IInvitationAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: invitationsRefetch,
    error,
  } = useQuery(Invitations, variables, { clientId: 'core', fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const invitations = computed(() => result.value?.invitations ?? []);
  return { invitations, loading, invitationsRefetch, error };
};

// mutations
const InvitationPost = ({ mutationType = 'add' }) => {
  const mutationMap: any = {
    add: { mutation: InvitationAdd, message: 'added' },
    delete: { mutation: InvitationDelete, message: 'cancelled' },
  };
  const {
    mutate: postInvitation,
    loading,
    onDone,
    onError,
  } = useMutation(mutationMap[mutationType].mutation, { clientId: 'core', throws: 'always' });
  onDone((result: any) => Vue.prototype.$toastr({ msg: `Invitation ${mutationMap[mutationType].message} successfully!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));
  return { postInvitation, loading };
};
const ResendInvitationPost = () => {
  const { mutate: postResendInvitation, loading, onDone, onError } = useMutation(InvitationResend, { clientId: 'core', throws: 'always' });
  onDone((result: any) => Vue.prototype.$toastr({ msg: `Invitation resend successfully!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));
  return { postResendInvitation, loading };
};
const AcceptInvitationPost = () => {
  const { mutate: postAcceptInvitation, loading, onDone, onError } = useMutation(InvitationAccept, { clientId: 'core', throws: 'always' });
  onDone((result: any) => {
    const { organizationName } = result.data?.invitationAccept;
    eventHub.$emit('modal:shared:show', {
      componentProps: { message: `You have been successfully added to ${organizationName}`, alertType: 'success' },
      notificationAlert: true,
      ...AlertData,
    });
  });
  onError((error) => {
    const message = handleError({ error, apollo: true, returnMessage: true });
    if (message) eventHub.$emit('modal:shared:show', { componentProps: { message, alertType: 'error' }, notificationAlert: true, ...AlertData });
  });
  return { postAcceptInvitation, loading };
};

export { InvitationFetch, InvitationsFetch, InvitationPost, ResendInvitationPost, AcceptInvitationPost };

interface IFilter {
  filterBy: string[];
  search?: string;
}
export interface IInvitationAPI {
  organizationId?: string;
  invitationId?: string;
  data?: any;
  queryType?: string;
  noCache?: boolean;
  mutationType?: string;
}
