import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { UserCurrent, UserSignIn, UserSignUp } from '@/graphql/core/user.gql';
import { handleError } from '@/composables/useErrorHandler';
// remove
import Vue from 'vue';
import router from '@/router';

const AUTH_TOKEN = 'token';

// queries
const userCurrentFetch = () => {
  const { result, onResult: onCurrentUserResult, loading, error } = useQuery(UserCurrent, {}, { clientId: 'core' });
  const currentUser: any = computed(() => result.value?.userCurrent ?? {});
  return { currentUser, onCurrentUserResult, loading, error };
};

// mutations
const userSignIn = ({ data }: IAuthAPI) => {
  const {
    mutate: signIn,
    loading,
    onDone,
    onError,
  } = useMutation(UserSignIn, {
    clientId: 'core',
    variables: { input: data },
    throws: 'always',
  });

  onDone((result: any) => {
    const { token } = result.data.userLogIn;
    localStorage.setItem(AUTH_TOKEN, token);
    router.push({ name: 'Dashboard' });
    Vue.prototype.$toastr({ msg: `Successfully Logged in!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { signIn, loading };
};

const userSignUp = ({ data }: IAuthAPI) => {
  const {
    mutate: signUp,
    loading,
    onDone,
    onError,
  } = useMutation(UserSignUp, {
    clientId: 'core',
    variables: { input: data },
    throws: 'always',
  });

  onDone(() => {
    Vue.prototype.$toastr({ msg: `Successfully signed up! Please log in.` });
    router.push({ name: 'Auth' });
  });
  onError((error) => handleError({ error, message: 'Sign Up Failed!', apollo: true, type: 'formAlert' }));

  return { signUp };
};

export { userCurrentFetch, userSignIn, userSignUp };

// TODO: remove
const useAuthAPI = () => ({ userCurrentFetch, userSignIn, userSignUp });
export default useAuthAPI;

interface IAuthAPI {
  data?: any;
}
