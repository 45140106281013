import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

const periodOptions = [
  { id: 'today', name: 'Today' },
  { id: 'thisWeek', name: 'This Week' },
  { id: 'thisMonth', name: 'This Month' },
  { id: 'thisQuarter', name: 'This Quarter' },
  { id: 'thisYear', name: 'This Year' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'previousWeek', name: 'Previous Week' },
  { id: 'previousMonth', name: 'Previous Month' },
  { id: 'previousQuarter', name: 'Previous Quarter' },
  { id: 'previousYear', name: 'Previous Year' },
  { id: 'allTime', name: 'All Time' },
  // { id: 'custom', name: 'Custom' },
];

const getPeriod = (periodName: string) => {
  switch (periodName) {
    case 'today':
      return { fromDate: dayjs().startOf('day').format() };
    case 'thisWeek':
      return { fromDate: dayjs().startOf('week').format() };
    case 'thisMonth':
      return { fromDate: dayjs().startOf('month').format() };
    case 'thisQuarter':
      return { fromDate: dayjs().startOf('quarter').format() };
    case 'thisYear':
      return { fromDate: dayjs().startOf('year').format() };
    case 'yesterday':
      const yesterdayEnd = dayjs().startOf('day');
      return { fromDate: yesterdayEnd.subtract(1, 'day').format(), toDate: yesterdayEnd.format() };
    case 'previousWeek':
      const previousWeekEnd = dayjs().startOf('week');
      return { fromDate: previousWeekEnd.subtract(1, 'week').format(), toDate: previousWeekEnd.format() };
    case 'previousMonth':
      const previousMonthEnd = dayjs().startOf('month');
      return { fromDate: previousMonthEnd.subtract(1, 'month').format(), toDate: previousMonthEnd.format() };
    case 'previousQuarter':
      const previousQuarterEnd = dayjs().startOf('quarter');
      return { fromDate: previousQuarterEnd.subtract(1, 'quarter').format(), toDate: previousQuarterEnd.format() };
    case 'previousYear':
      const previousYearEnd = dayjs().startOf('year');
      return { fromDate: previousYearEnd.subtract(1, 'year').format(), toDate: previousYearEnd.format() };
    default:
      return {};
  }
};

export { periodOptions, getPeriod };
