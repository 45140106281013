import { THandleErrors } from '@/utils/sharedTypes';
import eventHub from '@/utils/eventHub';
// remove
import Vue from 'vue';

const handleError: any = ({ error, type = 'toastAlert', apollo, modalNotification, ...otherArgs }: THandleErrors) => {
  let { message, errorList } = otherArgs;

  if (apollo === true) {
    const { graphQLErrors } = error;
    if (graphQLErrors.length) {
      message = message ? message : (graphQLErrors[0] || {}).message;
      const { extensions = {} } = graphQLErrors[0];
      errorList = Object.values(extensions.error || {});
    }
  }

  if (otherArgs.returnMessage) {
    return message;
  } else if (type === 'formAlert') {
    eventHub.$emit('alertForm:show', { strong: 'Hmm.', message, errorList, modalNotification });
  } else if (type === 'toastAlert') {
    Vue.prototype.$toastr({ type: 'error', msg: message });
  }
};

export { handleError };

// TODO: remove
const useErrorHandler = () => ({ handleError });
export default useErrorHandler;
