import { computed, Ref } from '@vue/composition-api';
import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
import {
  PurchaseOrder,
  PurchaseOrders,
  PurchaseOrderAdd,
  PurchaseOrderUpdate,
  PurchaseOrderDelete,
  PurchaseOrderFull,
  PurchaseOrderNextNo,
  PurchaseOrderView,
} from '@/graphql/purchases/purchaseOrder.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const purchaseOrderFetch = ({ purchaseOrderId, queryType = 'default', noCache = false }: IPurchaseOrderAPI) => {
  const queryMap: any = { default: PurchaseOrder, full: PurchaseOrderFull, view: PurchaseOrderView };
  const {
    result,
    onResult: onPurchaseOrderResult,
    refetch: purchaseOrderRefetch,
    loading,
    error,
  } = useQuery(
    queryMap[queryType],
    {
      id: purchaseOrderId,
    },
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first' },
  );
  const purchaseOrder = computed(() => result.value?.purchaseOrder ?? {});
  return { purchaseOrder, onPurchaseOrderResult, purchaseOrderRefetch, loading, error };
};

const purchaseOrderLazyFetch = ({ purchaseOrderId, queryType = 'default', noCache = false }: IPurchaseOrderLazyFetchAPI) => {
  const queryMap: any = { default: PurchaseOrder, full: PurchaseOrderFull, view: PurchaseOrderView };
  const {
    result,
    onResult: onPurchaseOrderResult,
    load: purchaseOrderQueryLoad,
  } = useLazyQuery(queryMap[queryType], { id: purchaseOrderId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const purchaseOrder = computed(() => result.value?.purchaseOrder ?? {});
  return { purchaseOrder, purchaseOrderQueryLoad, onPurchaseOrderResult };
};

const purchaseOrdersFetch = (variables: IFilter, noCache = false) => {
  const {
    result,
    loading,
    refetch: purchaseOrdersRefetch,
    error,
  } = useQuery(PurchaseOrders, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const purchaseOrders = computed(() => result.value?.purchaseOrders ?? {});
  return { purchaseOrders, purchaseOrdersRefetch, loading, error };
};

const purchaseOrderNextNoFetch = ({ organizationId }: IPurchaseOrderAPI) => {
  const { result, onResult: onPurchaseOrderNextNo, loading, error } = useQuery(PurchaseOrderNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const purchaseOrderNextNo = computed(() => result.value?.purchaseOrderNextNo ?? []);
  return { documentNextNo: purchaseOrderNextNo, onDocumentNextNo: onPurchaseOrderNextNo, loading, error };
};

// mutations
const purchaseOrderPost = ({ data, mutationType = 'add', modalNotification = false }: IPurchaseOrderAPI) => {
  const mutationMap: any = {
    add: { mutation: PurchaseOrderAdd, message: 'added' },
    update: { mutation: PurchaseOrderUpdate, message: 'updated' },
    void: { mutation: PurchaseOrderDelete, message: 'voided' },
  };
  const { mutate: postPurchaseOrder, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Purchase order successfully ${mutationMap[mutationType].message}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postPurchaseOrder, loading };
};

export { purchaseOrderFetch, purchaseOrderLazyFetch, purchaseOrdersFetch, purchaseOrderNextNoFetch, purchaseOrderPost };

interface IFilter {
  status: string[];
  search?: string;
}
interface IPurchaseOrderLazyFetchAPI {
  purchaseOrderId?: Ref<string | null>;
  queryType?: string;
  noCache?: boolean;
}
interface IPurchaseOrderAPI {
  organizationId?: string;
  purchaseOrderId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  noCache?: boolean;
  queryType?: string;
  mutationType?: string;
  modalNotification?: boolean;
}
