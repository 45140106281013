import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { Item, ItemFull, ItemView, ItemTransactions, Items, ItemAdd, ItemUpdate } from '@/graphql/inventory/item.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const itemFetch = ({ itemId, queryType = 'default', noCache = false }: IItemAPI) => {
  const queryMap: any = { default: Item, full: ItemFull, view: ItemView };
  const {
    result,
    onResult: onInventoryItemResult,
    loading,
    error,
    refetch: inventoryItemRefetch,
  } = useQuery(queryMap[queryType], { id: itemId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const inventoryItem = computed(() => result.value?.item ?? {});
  return { inventoryItem, onInventoryItemResult, inventoryItemRefetch, loading, error };
};

const itemTransactionsFetch = (variables: IItemTransactionsAPI) => {
  const { result, loading, refetch: itemTransactionsRefetch, error } = useQuery(ItemTransactions, variables, { fetchPolicy: 'no-cache' });
  const inventoryItem = computed(() => result.value?.itemTransactions ?? {});

  return { inventoryItem, loading, itemTransactionsRefetch, error };
};

const itemsFetch = (variables: IItemAPI, noCache = false) => {
  const { result, loading, refetch: itemsRefetch, error } = useQuery(Items, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const inventoryItems = computed(() => result.value?.items ?? []);

  return { inventoryItems, loading, itemsRefetch, error };
};

// mutations
const itemPost = ({ data, mutationType = 'add' }: IItemAPI) => {
  const mutationMap: any = {
    add: ItemAdd,
    update: ItemUpdate,
  };
  const { mutate: postItem, loading, onDone, onError } = useMutation(mutationMap[mutationType], { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Item successfully ${mutationType === 'add' ? 'added' : 'updated'}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { postItem, loading };
};

export { itemFetch, itemTransactionsFetch, itemsFetch, itemPost };

interface IFilter {
  filterBy: string[];
  search?: string;
}
interface IItemTransactionsFilter {
  transactionType: string;
  transactionStatus?: string | null;
}
export interface IItemTransactionsAPI {
  id: string;
  filter: IItemTransactionsFilter;
  pager: IPager;
}
export interface IItemAPI {
  organizationId?: string;
  itemId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  queryType?: string;
  noCache?: boolean;
  mutationType?: string;
}
