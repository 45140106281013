import { ref, reactive, computed, watch, ComputedRef, onMounted, Ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, decimal, minValue } from '@vuelidate/validators';
import eventHub from '@/utils/eventHub';
import { NonBaseCurrencyWarningData, ConfigDocumentNoData } from '@/utils/data';

// main document data
export const useSalesDocument = ({ props, root, docName }: IISalesDocument) => {
  const documentName = ref('');
  documentName.value = docName;

  const model: any = reactive({
    organizationId: root.currentOrganizationId,
    accountId: null,
    currencyId: null,
    documentNo: null,
    documentDate: new Date(),
    dueDate: new Date(),
    exchangeRate: 1,
    discountPercentage: true,
    discount: 0,
    shippingCharges: 0,
    otherCharges: 0,
    grandTotal: 0,
    dueAmount: 0,
    discountAmount: 0,
    untaxedSubTotalDiscount: 0,
    subTotal: 0,

    // shared partially
    paymentTerms: 'dueOnReceipt',

    // salesOrder
    estimateId: null,

    // invoice
    paymentMade: 0,
    creditsApplied: 0,
    salesOrderId: null,

    // creditNote
    amountUsed: 0,
    amountRefunded: 0,
    balance: 0,
    invoiceId: null,

    // other shared
    addresses: [],
    lineItems: [],
    taxes: [],
    options: { otherChargesName: 'Adjustment', taxExclusive: true },
  });
  const v$: any = useVuelidate(
    {
      model: {
        accountId: { required },
        documentNo: { required },
        documentDate: { required },
        exchangeRate: { required, decimal, minValue: minValue(1) },
        referenceNo: {},
      },
    },
    { model },
    { $autoDirty: true, $lazy: true },
  );

  const changeModelTaxType = (isTaxExclusive: boolean) => (model.options.taxExclusive = isTaxExclusive);

  return {
    model,
    v$,
    documentName,
    editDocument: computed(() => !!props.itemId),
    cloneDocumentId: computed(() => root.$route.query.clone),
    changeModelTaxType,
  };
};

// core organization data
import { organizationListItemsFetch } from '@/composables/core/useOrganizationAPI';
export const useOrganizationList = ({ organizationId, model, documentName, accountType = 'customer' }: IUseOrganizationList) => {
  const { taxes, currencies, taxesFormatted, baseCurrency, onOrgListItemsResult } = organizationListItemsFetch({
    organizationId,
    fields: ['taxes', 'currencies'],
  });
  onOrgListItemsResult(() => {
    if (!model.currencyId) model.currencyId = baseCurrency.value.id;
  });
  const currency = computed(() => currencies.value.find((c: any) => c.id === model.currencyId) || {});
  watch(
    () => model.currencyId,
    () => {
      if (currency.value.code === baseCurrency.value.code) return;
      eventHub.$emit('modal:shared:show', {
        ...NonBaseCurrencyWarningData,
        componentProps: { accountType, documentName: documentName.value },
      });
    },
  );

  return {
    taxes,
    taxesFormatted,
    currency,
    currencies,
    baseCurrency,
  };
};

// customers data
import { accountFetch, accountsFetch } from '@/composables/crm/useAccountAPI';
export const useCrmAccount = ({ model, accountType }: IUseCrmAccounts) => {
  const accountsParams: any = computed(() => ({
    organizationId: model.organizationId,
    pager: { currentPage: 1, perPage: 10, sortBy: 'name', sortDesc: false },
    filter: { status: 'active', accountType },
  }));
  const { accounts, accountsRefetch, onAccountsFetch } = accountsFetch({ params: accountsParams.value, queryType: 'sales', noCache: true });
  const account: any = computed(() => accounts.value.items?.find((a: any) => a.id === model.accountId) || {});
  const getAccounts = Debounce((search: any, loading: any) => {
    accountsParams.value.filter.search = search;
    accountsRefetch(accountsParams.value);
  });

  const { account: accountFetched, accountRefetch } = accountFetch({ accountId: model.accountId, noCache: true });
  onAccountsFetch(async () => {
    if (account.value?.id || !model.accountId) return;
    if (!accountFetched.value?.id) await accountRefetch({ id: model.accountId });
    accounts.value.items?.unshift(accountFetched.value);
  });

  watch(
    () => model.accountId,
    () => {
      if (account.value.currencyId) model.currencyId = account.value.currencyId;
      if (account.value.addresses && accountType === 'customer') {
        model.addresses = account.value.addresses?.filter((a: any) => ['billing', 'shipping'].includes(a.name));
      }
    },
  );

  // for customer accounts
  const customerZeroCredit = computed(() => account?.value.creditLimit === 0);

  return {
    account,
    accounts,
    onAccountsFetch,
    customerZeroCredit,
    getAccounts,
  };
};

// organization data
import { organizationDataFetch } from '@/composables/others/useOrganizationDataAPI';
import { ChangeFirstLetterCase } from '@/utils/sharedMethods';
export const useOrganizationData = ({ organizationId, documentName, moreTypes = [] }: IUseOrganizationData) => {
  const documentNameRef = ref(documentName);
  const queryTypes = ['paymentTerms', 'settingsInventory', 'settingsSales', ...moreTypes];

  const { paymentModes, paymentTerms, settingsInventory, settingsSales, onOrganizationDataFetch } = organizationDataFetch({
    organizationId,
    types: queryTypes,
  });
  const settingsSalesGeneral = computed(() => settingsSales.value?.general || {});
  const settingsDocument = computed(() => settingsSales.value?.[ChangeFirstLetterCase(documentNameRef.value, 'lower')] ?? {});
  const availableStockType = computed(() => {
    return settingsInventory.value?.general?.stockTrackingMode === 'accountingStock' ? 'accountingAvailableStock' : 'physicalAvailableStock';
  });
  const preventStockBelowZero = computed(() => settingsInventory.value?.items?.preventStockBelowZero);

  return {
    paymentModes,
    paymentTerms,
    settingsSalesGeneral,
    settingsDocument,
    availableStockType,
    preventStockBelowZero,
    onOrganizationDataFetch,
  };
};

// document numbering
import { ToTitleCase } from '@/utils/sharedMethods';
export const useDocumentNumbering = ({
  model,
  documentName,
  fetchFunction,
  settingsDocument,
  filter = null,
  settingCategory,
  settingModule,
}: IUseDocumentNumbering) => {
  const { documentNextNo, onDocumentNextNo } = fetchFunction({ organizationId: model.organizationId, ...(filter && { ...filter }) });
  const assignDocumentNo = () => {
    const { documentNoAutoGen } = settingsDocument.value;
    // TODO: remove after adding purchases or other settings
    const overrideSettings = ['settingsPurchases', 'settingsInventory'].includes(settingCategory);
    if (documentNoAutoGen || overrideSettings) model.documentNo = documentNextNo.value;
  };
  onDocumentNextNo(() => assignDocumentNo());
  watch(settingsDocument, () => assignDocumentNo());
  const configDocumentNo = (useCustomNo = false) => {
    ConfigDocumentNoData.title = `${ToTitleCase(documentName)}#`;
    const configDocumentNoProps = {
      documentName,
      document: model,
      settingsDocument: { ...settingsDocument.value },
      settingCategory,
      settingModule,
      nextDocumentNoObj: { documentNo: documentNextNo.value },
      currentDocumentNo: model.documentNo,
      useCustomNo,
    };
    eventHub.$emit('modal:shared:show', { ...ConfigDocumentNoData, componentProps: configDocumentNoProps });
  };
  const onChangeDocumentNo = () => {
    if (settingsDocument.value.documentNoAutoGen && model.documentNo !== documentNextNo.value) configDocumentNo(true);
  };

  return {
    configDocumentNo,
    onChangeDocumentNo,
  };
};

// line items interaction
import { Debounce, Round } from '@/utils/sharedMethods';
export const useLineItems = ({ model, newLineItemCustom }: IUseLineItems) => {
  watch(
    () => model.exchangeRate,
    Debounce((newRate: any, oldRate = 1) => {
      model.lineItems.forEach((i: any) => {
        if (i.itemId) i.rate = Round(i.itemRate / (model.exchangeRate || 1), 6);
      });
    }),
  );

  const newLineItem: any = newLineItemCustom ?? {
    itemId: null,
    searchQuery: null,
    quantity: 1,
    rate: 0,
    discountPercentage: true,
    discount: 0,
    tax: { taxId: null },
    total: 0,
    inventoryItem: null,
    inventoryInfo: null,
    // creditNote
    coaId: null,
  };
  const addLineItem = () => model.lineItems.push({ ...newLineItem });
  const resetLineItem = (index: number) => Object.assign(model.lineItems[index], { ...newLineItem, itemId: null, searchQuery: null, coaId: null });
  const removeLineItem = (index: number) => model.lineItems.splice(index, 1);
  // add addItemsBulk = () => {};
  const autoAddNewLineItem = () => {
    const emptyLineItems: any = model.lineItems.filter((i: any) => !i.itemId && !i.searchQuery).length;
    if (emptyLineItems === 0) addLineItem();
  };

  return {
    newLineItem,
    addLineItem,
    resetLineItem,
    removeLineItem,
    autoAddNewLineItem,
  };
};

// Table total section
export const useDocumentCalculator = ({ model, documentName, organizationId }: IRequiredModel) => {
  const { taxes, currency } = useOrganizationList({ organizationId, model, documentName });
  const { settingsSalesGeneral } = useOrganizationData({ documentName, organizationId });

  const decimalPlaces: ComputedRef<number> = computed(() => currency.value.format?.decimals || 2);
  const lineItemsToWatch = computed(() =>
    model.lineItems.map(({ quantity, rate, discount, discountPercentage, tax: { taxId } }: any) => ({
      quantity,
      rate,
      discount,
      discountPercentage,
      taxId,
    })),
  );
  watch(lineItemsToWatch, () => documentCalculator(), { deep: true });
  const lineItemCalculator = (lineItem: any) => {
    const {
      quantity,
      rate,
      discountPercentage,
      discount,
      tax: { taxId },
    } = lineItem;
    // update itemRate incase exchange rate changes later
    lineItem.itemRate = (model.exchangeRate || 1) * rate;

    const subTotal = Round(quantity * rate, decimalPlaces.value);
    // calculate discount
    // ZOHO is taxing the discountAmount first before deducting it from the amount when not discountPercentage. Why?
    const discountAmount = Round(discountPercentage ? (discount / 100) * subTotal : discount, decimalPlaces.value);
    lineItem.discountAmount = discountAmount;
    // calculate totals
    const total = Round(subTotal - discountAmount, decimalPlaces.value);
    lineItem.total = total;
    // calculate taxes
    if (taxId) {
      const { name, rate: taxRate } = taxes.value.find((t: any) => t.id === taxId) || {};
      const taxAmount = Round(total * (taxRate / (100 + (model.options.taxExclusive ? 0 : taxRate))), decimalPlaces.value);
      lineItem.tax = { taxId, name, rate: taxRate, amount: taxAmount };
    }

    return lineItem;
  };
  const documentCalculator = Debounce((value: any, oldValue: any) => {
    const { discountPercentage, discount, shippingCharges, otherCharges } = model;
    let subTotal = 0;
    let untaxedSubTotal = 0;
    let taxAmount = 0;
    let discountAmount = 0;
    let untaxedSubTotalDiscount = 0;
    const modelTaxes: any[] = [];

    // calculate taxes and subtotal from items
    for (const item of model.lineItems) {
      if ((!item.itemId && !item.searchQuery) || item.rate <= 0) continue;

      const {
        total,
        discountAmount: discountAmountLineItem,
        tax: { id: taxId, rate: taxRate, amount: itemTaxAmount },
      } = lineItemCalculator(item);

      subTotal += total;
      // handle discounts
      if (settingsSalesGeneral.value.discount === 'lineItemLevel') {
        discountAmount += parseFloat(discountAmountLineItem) || 0;
      }
      // handle taxes
      if (itemTaxAmount) {
        const modelTax = modelTaxes.find((t: any) => t.id === taxId && t.rate === taxRate);
        if (modelTax) {
          modelTax.amount += itemTaxAmount;
          modelTax.taxableAmount += total;
        } else {
          modelTaxes.push({ ...item.tax, taxableAmount: total });
        }
        taxAmount += itemTaxAmount;
      }
    }

    // calculate discount
    if (settingsSalesGeneral.value.discount === 'transactionLevel') {
      const discountBeforeTax = settingsSalesGeneral.value.discountTime === 'beforeTax';
      // update the tax amounts since we should tax after discount
      if (discountBeforeTax) {
        taxAmount = 0;
        const discountRate = discountPercentage ? discount : (discount / subTotal) * 100;
        // loop through the taxes and update them since they were calculated after discount
        for (const tax of modelTaxes) {
          const untaxedAmount = (100 / (100 + (model.options.taxExclusive ? 0 : tax.rate))) * tax.taxableAmount;
          untaxedSubTotal += untaxedAmount;
          const taxableAmountDiscount = (discountRate / 100) * untaxedAmount;
          const discountedTaxableAmount = untaxedAmount - taxableAmountDiscount;
          const newtaxAmount = Round(discountedTaxableAmount * (tax.rate / 100), decimalPlaces.value);
          tax.amount = newtaxAmount;
          taxAmount += newtaxAmount;
        }
        // needed because we'll show a different discount amount from the real one when taxInclusive
        untaxedSubTotalDiscount = Round(model.options.taxExclusive ? 0 : (discount / 100) * untaxedSubTotal, decimalPlaces.value);
        discountAmount = Round(discountPercentage ? (discount / 100) * subTotal : discount, decimalPlaces.value);
      } else {
        discountAmount = Round((discountAmount = discountPercentage ? (discount / 100) * (subTotal + taxAmount) : discount), decimalPlaces.value);
      }
    }

    // final calculations
    let grandTotal =
      subTotal + (model.options.taxExclusive ? taxAmount : 0) + (parseFloat(otherCharges) || 0) - (parseFloat(shippingCharges) || 0) || 0;
    if (settingsSalesGeneral.value.discount === 'transactionLevel') grandTotal -= discountAmount;

    const finalGrandTotal = (settingsSalesGeneral.value.roundOffTotal ? Round(grandTotal, 0) : Round(grandTotal, decimalPlaces.value)) || 0;
    const roundOff = grandTotal - finalGrandTotal;

    Object.assign(model, {
      taxes: modelTaxes,
      discountAmount,
      untaxedSubTotalDiscount,
      taxAmount,
      subTotal,
      untaxedSubTotal,
      roundOff,
      grandTotal: finalGrandTotal,
    });
  });
  watch(
    () => [model.exchangeRate, model.discount, model.discountPercentage, model.shippingCharges, model.otherCharges, model.options.taxExclusive],
    () => documentCalculator(),
  );
};

// document submitting
import { OmitDeep, FormatFields } from '@/utils/sharedMethods';
export const useDocumentSubmit = ({ model, omitKeys = [] }: IUseDocumentSubmit) => {
  const filteredLineItems = model.lineItems.map((i: any) => (i.itemId || i.searchQuery ? { ...i } : undefined)).filter((i: any) => !!i);
  let modelData = Object.assign({}, { ...model, lineItems: filteredLineItems });
  modelData = OmitDeep(
    modelData,
    [
      'account',
      'accountFull',
      'dueAmount',
      'statusFormatted',
      'currency',
      'lineItemsFull',
      'roundOff',
      'type',
      'itemRate',
      'itemQuantity',
      'inventoryItem',
      'inventoryInfo',
      'createdAt',
      'updatedAt',
      '__typename',
      ...omitKeys,
    ],
    [null],
  );
  // keep lineItems ids
  delete modelData.id;
  modelData = FormatFields(modelData, { toFloat: ['exchangeRate', 'shippingCharges', 'otherCharges', 'quantity', 'rate', 'discount'] });

  return {
    modelData,
  };
};

// typescript types
interface IISalesDocument {
  props: any;
  root: any;
  docName: string;
}
interface IRequiredModel {
  model: any;
  documentName: Ref<string>;
  organizationId: string;
}
interface IUseOrganizationList extends IRequiredModel {
  accountType?: string;
}
interface IUseOrganizationData {
  documentName: string | Ref<string>;
  moreTypes?: string[];
  organizationId: string;
}
interface IUseCrmAccounts extends IRequiredModel {
  accountType: string;
}
interface IUseDocumentNumbering {
  model: any;
  documentName: string;
  fetchFunction: any;
  settingsDocument: any;
  settingCategory: string;
  settingModule: string;
  filter?: any;
}
interface IUseLineItems {
  model: any;
  newLineItemCustom?: any;
}
interface IUseDocumentSubmit {
  model: any;
  omitKeys?: string[] | undefined;
}
