import './utils/hooks';
import Vue from 'vue';
import VueCompositionAPI, { provide } from '@vue/composition-api';
import { ApolloClients } from '@vue/apollo-composable';
import Vuelidate from 'vuelidate';
import { ModalPlugin, TablePlugin, PaginationPlugin } from 'bootstrap-vue';
import toastr from 'toastr/build/toastr.min.js';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import './utils/filters';
// components
import AlertForm from '@/components/layout/AlertForm.vue';
import ModalShared from '@/components/shared/ModalShared.vue';
// input components
import FormInput from '@/components/form/Input.vue';
import Multiselect from '@/components/form/Multiselect.vue';
import FormTags from '@/components/form/Tags.vue';
import CurrencyInput from '@/components/form/CurrencyInput.vue';
import FormRadioGroup from '@/components/form/RadioGroup.vue';
import CheckboxGroup from '@/components/form/CheckboxGroup.vue';
import DatePicker from 'vue2-datepicker';

// modules
import 'bootstrap/dist/js/bootstrap.min.js';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'block-ui/jquery.blockUI.js';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.min.js';
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'toastr/build/toastr.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
// style Files
import './assets/vendors/fontawesome5/css/all.min.css';
import './assets/vendors/flaticon/css/flaticon.css';
import './assets/vendors/line-awesome/css/line-awesome.css';
import './assets/vendors/metronic/css/styles.css';
import './assets/sass/style.scss';
import { apolloCoreClient, apolloErpClient } from './apollo-composable';
import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;
// @ts-ignore
Vue.use(VueCompositionAPI);
Vue.use(Vuelidate);
Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
// components
Vue.component('AlertForm', AlertForm);
Vue.component('ModalShared', ModalShared);
Vue.component('Multiselect', Multiselect);
Vue.component('FormInput', FormInput);
Vue.component('FormTags', FormTags);
Vue.component('CurrencyInput', CurrencyInput);
Vue.component('FormRadioGroup', FormRadioGroup);
Vue.component('CheckboxGroup', CheckboxGroup);
Vue.component('DatePicker', DatePicker);
// gql
import { AccessRoleCurrent } from '@/graphql/core/accessRole.gql';

// prototypes
Vue.prototype.$toastr = ({ type = 'success', msg, title }: any) => {
  // types: info, success, warning, error
  toastr.options.closeButton = true;
  toastr[type](msg, title);
};
new Vue({
  setup() {
    provide(ApolloClients, {
      default: apolloErpClient,
      core: apolloCoreClient,
    });
  },
  data: {
    currentOrganizationId: '',
    currentAccessRole: null,
    // TODO: Query organization settings once - update them if settings change
    // currentOrganizationSettings: {},
  },
  methods: {
    setCurrentOrganization(this: any, organizationId: string) {
      this.$root.currentOrganizationId = organizationId;
      localStorage.setItem('currentOrganizationId', organizationId);
      // this.$apollo.getClient().writeData({
      //   data: { currentOrganizationId: organizationId },
      // });
    },
    changeOrganization(this: any, organizationId: string) {
      this.setCurrentOrganization(organizationId);
      this.$router.push({ name: 'Dashboard' }).catch((err: any) => {});
    },
  },
  async created(this: any) {
    // TEST: there is no need to use apollo localStorage, it's double work
    const currentOrganizationId: string | any = localStorage.getItem('currentOrganizationId');
    this.$root.currentOrganizationId = currentOrganizationId;

    // fetch and set the user access role in the current organization
    await this.$apollo
      .query({
        client: 'core',
        query: AccessRoleCurrent,
        fetchPolicy: 'network-only',
        variables: { organizationId: this.$root.currentOrganizationId },
      })
      .then((res: any) => {
        this.$root.currentAccessRole = res.data.accessRoleCurrent;
      })
      .catch((error: any) => {});
  },
  router,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');

// // Vue 3 setup
// import VueCompositionAPI, { createApp, provide } from '@vue/composition-api';
// import AppComposable from './AppComposable.vue';
//
// const app: any = createApp({
//   setup() {
//     provide(ApolloClients, {
//       default: apolloErpClient,
//       core: apolloCoreClient,
//     });
//   },
//   render(h: any) {
//     return h(AppComposable);
//   },
// }).mount('#app');

// // imported components
// app.use(VueCompositionAPI);
// // TODO: use the new Vuelidate
// // app.use(Vuelidate);
// app.use(router);
// app.use(ModalPlugin);
// app.use(TablePlugin);
// app.use(PaginationPlugin);
// // custom components
// app.component('AlertForm', AlertForm);
// app.component('ModalShared', ModalShared);
// app.component('Multiselect', Multiselect);
// // global api
// app.config.performance = true;
// app.config.globalProperties.$toastr = ({ type = 'success', msg, title }: any) => {
//   // types: info, success, warning, error
//   toastr.options.closeButton = true;
//   toastr[type](msg, title);
// };
