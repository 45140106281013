import { computed, Ref } from '@vue/composition-api';
import { handleError } from '@/composables/useErrorHandler';
import {
  ItemAdjustment,
  ItemAdjustmentView,
  ItemAdjustmentFull,
  ItemAdjustments,
  ItemAdjustmentAdd,
  ItemAdjustmentUpdate,
  ItemAdjustmentDelete,
} from '@/graphql/inventory/itemAdjustment.gql';
// types
import { IFilter, IPager } from '@/types/others/shared';
import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
// remove
import Vue from 'vue';

// queries
const itemAdjustmentFetch = ({ itemAdjustmentId, queryType = 'default', noCache = false }: IItemAdjustmentAPI) => {
  const queryMap: any = { default: ItemAdjustment, full: ItemAdjustmentFull, view: ItemAdjustmentView };
  const {
    result,
    onResult: onItemAdjustmentResult,
    loading,
    refetch: itemAdjustmentRefetch,
    error,
  } = useQuery(queryMap[queryType], { id: itemAdjustmentId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const itemAdjustment = computed(() => result.value?.itemAdjustment ?? { currency: {} });
  return { itemAdjustment, onItemAdjustmentResult, loading, itemAdjustmentRefetch, error };
};

const itemAdjustmentsFetch = (variables: IItemAdjustmentAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: itemAdjustmentsRefetch,
    onResult: onItemAdjustmentsResult,
    error,
  } = useQuery(ItemAdjustments, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const itemAdjustments = computed(() => result.value?.itemAdjustments ?? []);
  return { itemAdjustments, loading, itemAdjustmentsRefetch, onItemAdjustmentsResult, error };
};

// mutations
const itemAdjustmentPost = ({ mutationType = 'add', modalNotification = false }: IItemAdjustmentAPI) => {
  const mutationMap: any = {
    add: { mutation: ItemAdjustmentAdd, message: 'added' },
    update: { mutation: ItemAdjustmentUpdate, message: 'updated' },
    void: { mutation: ItemAdjustmentDelete, message: 'voided' },
  };
  const { mutate: postItemAdjustment, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `Item adjustment successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postItemAdjustment, loading };
};

export { itemAdjustmentFetch, itemAdjustmentsFetch, itemAdjustmentPost };

// types
interface IItemAdjustmentFilter extends IFilter {
  accountId: string;
  withDueAmount: boolean;
}
interface IItemAdjustmentAPI {
  organizationId?: string;
  itemAdjustmentId?: string;
  pager?: IPager;
  filter?: IItemAdjustmentFilter;
  queryType?: string;
  noCache?: boolean;
  // for post
  data?: any;
  mutationType?: string;
  modalNotification?: boolean;
}
