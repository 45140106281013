import { useQuery, useMutation } from '@vue/apollo-composable';
import {
  VendorCredits,
  VendorCredit,
  VendorCreditView,
  VendorCreditFull,
  VendorCreditNextNo,
  VendorCreditAdd,
  VendorCreditUpdate,
  VendorCreditDelete,
  VendorCreditApplyCredits,
  VendorCreditReverseCredits,
  VendorCreditRefund,
} from '@/graphql/purchases/vendorCredit.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';
import { computed } from 'vue-demi';

const vendorCreditFetch = ({ vendorCreditId, queryType = 'default', noCache = false }: IVendorCreditAPI) => {
  const queryMap: any = { default: VendorCredit, full: VendorCreditFull, view: VendorCreditView };
  const {
    result,
    onResult: onVendorCreditResult,
    loading,
    refetch: vendorCreditRefetch,
    error,
  } = useQuery(queryMap[queryType], { id: vendorCreditId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const vendorCredit: any = computed(() => result.value?.vendorCredit ?? {});
  return { vendorCredit, onVendorCreditResult, vendorCreditRefetch, loading, error };
};
const vendorCreditsFetch = (variables: IFilter, noCache = false) => {
  const {
    result,
    loading,
    refetch: vendorCreditsRefetch,
    error,
  } = useQuery(VendorCredits, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const vendorCredits = computed(() => result.value?.vendorCredits ?? {});
  return { vendorCredits, vendorCreditsRefetch, loading, error };
};
const vendorCreditNextNoFetch = ({ organizationId }: IVendorCreditAPI) => {
  const { result, onResult: onVendorCreditNextNo, loading, error } = useQuery(VendorCreditNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const vendorCreditNextNo = computed(() => result.value?.vendorCreditNextNo ?? []);
  return { documentNextNo: vendorCreditNextNo, onDocumentNextNo: onVendorCreditNextNo, loading, error };
};

// mutations
const vendorCreditPost = ({ data, mutationType = 'add', modalNotification = false }: IVendorCreditAPI) => {
  const mutationMap: any = {
    add: { mutation: VendorCreditAdd, message: 'added' },
    update: { mutation: VendorCreditUpdate, message: 'updated' },
    void: { mutation: VendorCreditDelete, message: 'voided' },
    applyCredits: { mutation: VendorCreditApplyCredits, message: 'credited' },
    reverseCredits: { mutation: VendorCreditReverseCredits, message: 'reversed' },
    refund: { mutation: VendorCreditRefund, message: 'refunded' },
    refundDelete: { mutation: VendorCreditRefund, message: 'deleted refund' },
  };
  const { mutate: postVendorCredit, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Credit Note successfully ${mutationMap[mutationType].message}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postVendorCredit, loading };
};

export { vendorCreditFetch, vendorCreditsFetch, vendorCreditNextNoFetch, vendorCreditPost };

interface IFilter {
  status: string[];
  search?: string;
}
interface IVendorCreditAPI {
  organizationId?: string;
  vendorCreditId?: string;
  pager?: IPager;
  filter?: IFilter;
  noCache?: boolean;
  queryType?: string;
  // for post
  data?: any;
  mutationType?: string;
  modalNotification?: boolean;
}
