import { computed } from '@vue/composition-api';
import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
import {
  Account,
  Accounts,
  AccountFull,
  AccountCustomerCredits,
  AccountVendorCredits,
  AccountsForSales,
  AccountAdd,
  AccountUpdate,
  AccountDelete,
} from '@/graphql/crm/account.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const accountFetch = ({ accountId, queryType = 'default', noCache = false }: IAccountAPI) => {
  const queryMap: any = {
    default: Account,
    full: AccountFull,
  };
  const {
    result,
    loading,
    onResult: onAccountResult,
    refetch: accountRefetch,
  } = useQuery(queryMap[queryType], { id: accountId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });

  const account: any = computed(() => result.value?.account ?? {});

  const primaryContact = computed(() => account.value.contacts?.find((c: any) => c.primary) || {});
  const otherContacts = computed(() => account.value.contacts?.filter((a: any) => !a.primary) || []);
  const billingAddresses = computed(() => account.value?.addresses?.filter((b: any) => b.name === 'billing') || []);
  const shippingAddresses = computed(() => account.value?.addresses?.filter((s: any) => s.name === 'shipping') || []);

  return { account, primaryContact, billingAddresses, shippingAddresses, otherContacts, onAccountResult, accountRefetch, loading };
};

const accountCreditsFetch = ({ accountType, ...variables }: IAccountCreditsAPI) => {
  const {
    result,
    loading,
    onResult: onAccountCreditsResult,
  } = useQuery(accountType === 'vendor' ? AccountVendorCredits : AccountCustomerCredits, variables);

  const payments: any = computed(() => result.value?.payments.items ?? []);
  const creditNotes: any = computed(() => result.value?.creditNotes.items ?? []);
  const vendorCredits: any = computed(() => result.value?.vendorCredits.items ?? []);

  return { payments, creditNotes, vendorCredits, loading, onAccountCreditsResult };
};

const accountsFetch = ({ params, queryType = 'default', noCache = false }: IAccountsAPI) => {
  const queryMap: any = {
    default: Accounts,
    sales: AccountsForSales,
  };
  const {
    result,
    onResult: onAccountsFetch,
    loading,
    refetch: accountsRefetch,
    error,
  } = useQuery(queryMap[queryType], params, { fetchPolicy: noCache ? 'no-cache' : 'cache-first', debounce: 500 });

  const accounts = computed(() => result.value?.accounts ?? {});
  return { accounts, onAccountsFetch, loading, accountsRefetch, error };
};

// mutations
const accountPost = ({ data, mutationType = 'add' }: IAccountAPI) => {
  const mutationMap: any = {
    add: { mutation: AccountAdd, message: 'added' },
    update: { mutation: AccountUpdate, message: 'updated' },
    void: { mutation: AccountDelete, message: 'voided' },
  };
  const { mutate: postAccount, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `Account successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { postAccount, loading };
};

export { accountFetch, accountCreditsFetch, accountsFetch, accountPost };

interface IFilter {
  accountType?: string;
  search?: string;
}
interface IParams {
  organizationId: string;
  pager?: IPager;
  filter?: IFilter;
}
interface IAccountsAPI {
  params?: IParams | any;
  queryType?: string;
  noCache?: boolean;
}
interface IAccountCreditsAPI {
  accountType: string;
  organizationId: string;
  pager?: IPager;
  paymentFilter: any;
  vendorCreditFilter?: any;
  creditNoteFilter?: any;
}
interface IAccountAPI {
  accountId?: string;
  queryType?: string;
  data?: any;
  noCache?: boolean;
  mutationType?: string;
}
