import { computed, Ref } from '@vue/composition-api';
import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
import {
  SalesOrder,
  SalesOrders,
  SalesOrderAdd,
  SalesOrderUpdate,
  SalesOrderDelete,
  SalesOrderFull,
  SalesOrderNextNo,
  SalesOrderView,
} from '@/graphql/sales/salesOrder.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const salesOrderFetch = ({ salesOrderId, queryType = 'default', noCache = false }: ISalesOrderAPI) => {
  const queryMap: any = { default: SalesOrder, full: SalesOrderFull, view: SalesOrderView };
  const {
    result,
    onResult: onSalesOrderResult,
    refetch: salesOrderRefetch,
    loading,
    error,
  } = useQuery(
    queryMap[queryType],
    {
      id: salesOrderId,
    },
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first' },
  );
  const salesOrder = computed(() => result.value?.salesOrder ?? {});
  return { salesOrder, onSalesOrderResult, salesOrderRefetch, loading, error };
};

const salesOrderLazyFetch = ({ salesOrderId, queryType = 'default', noCache = false }: ISalesOrderLazyFetchAPI) => {
  const queryMap: any = { default: SalesOrder, full: SalesOrderFull, view: SalesOrderView };
  const {
    result,
    onResult: onSalesOrderResult,
    load: salesOrderQueryLoad,
  } = useLazyQuery(queryMap[queryType], { id: salesOrderId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const salesOrder = computed(() => result.value?.salesOrder ?? {});
  return { salesOrder, salesOrderQueryLoad, onSalesOrderResult };
};

const salesOrdersFetch = (variables: IFilter, noCache = false) => {
  const {
    result,
    loading,
    refetch: salesOrdersRefetch,
    error,
  } = useQuery(SalesOrders, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const salesOrders = computed(() => result.value?.salesOrders ?? {});
  return { salesOrders, salesOrdersRefetch, loading, error };
};

const salesOrderNextNoFetch = ({ organizationId }: ISalesOrderAPI) => {
  const { result, onResult: onSalesOrderNextNo, loading, error } = useQuery(SalesOrderNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const salesOrderNextNo = computed(() => result.value?.salesOrderNextNo ?? []);
  return { documentNextNo: salesOrderNextNo, onDocumentNextNo: onSalesOrderNextNo, loading, error };
};

// mutations
const salesOrderPost = ({ data, mutationType = 'add', modalNotification = false }: ISalesOrderAPI) => {
  const mutationMap: any = {
    add: { mutation: SalesOrderAdd, message: 'added' },
    update: { mutation: SalesOrderUpdate, message: 'updated' },
    void: { mutation: SalesOrderDelete, message: 'voided' },
  };
  const { mutate: postSalesOrder, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Salesorder successfully ${mutationMap[mutationType].message}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postSalesOrder, loading };
};

export { salesOrderFetch, salesOrderLazyFetch, salesOrdersFetch, salesOrderNextNoFetch, salesOrderPost };

interface IFilter {
  status: string[];
  search?: string;
}
interface ISalesOrderLazyFetchAPI {
  salesOrderId?: Ref<string | null>;
  queryType?: string;
  noCache?: boolean;
}
interface ISalesOrderAPI {
  organizationId?: string;
  salesOrderId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  noCache?: boolean;
  queryType?: string;
  mutationType?: string;
  modalNotification?: boolean;
}
