import { getCurrentInstance } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { computed } from '@vue/composition-api';
import { ItemGroup, ItemGroupFull, ItemGroups, ItemGroupAdd, ItemGroupUpdate, ItemGroupDelete } from '@/graphql/inventory/itemGroup.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IFilter, IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const itemGroupFetch = ({ itemId, queryType = 'default', noCache = false }: IItemAPI) => {
  const queryMap: any = { default: ItemGroup, full: ItemGroupFull };
  const {
    result,
    onResult: itemGroupResult,
    loading,
    error,
  } = useQuery(
    queryMap[queryType],
    {
      id: itemId,
    },
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first' },
  );

  const itemGroup: any = computed(() => result.value?.itemGroup ?? {});

  return { itemGroup, itemGroupResult, loading, error };
};

const itemGroupsFetch = (variables: IItemAPI) => {
  const { result, loading, refetch: itemGroupsRefetch, error } = useQuery(ItemGroups, variables);
  // ISalesDocumentPaged
  const itemGroups = computed(() => result.value?.itemGroups ?? []);
  return { itemGroups, loading, itemGroupsRefetch, error };
};

// mutations
const itemGroupPost = ({ mutationType = 'add' }: IItemAPI) => {
  const mutationMap: any = {
    add: { mutation: ItemGroupAdd, message: 'added' },
    update: { mutation: ItemGroupUpdate, message: 'updated' },
    delete: { mutation: ItemGroupDelete, message: 'deleted' },
  };
  const { mutate: postItemGroup, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Item Group successfully ${mutationMap[mutationType].message}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { postItemGroup, loading };
};

export { itemGroupFetch, itemGroupsFetch, itemGroupPost };

interface IItemAPI {
  organizationId?: string;
  itemId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  queryType?: string;
  noCache?: boolean;
  mutationType?: string;
}
