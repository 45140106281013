// import * as dotenv from 'dotenv';
// dotenv.config();

const sharedConfig: any = {
  environment: process.env.NODE_ENV,
  baseUrl: process.env.VUE_APP_BASE_URL,
  baseUrlCore: process.env.VUE_APP_BASE_URL_CORE,
  baseUrlErp: process.env.VUE_APP_BASE_URL_ERP,
  cloudinaryApiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
};

// You can override specific settings under the specific env
const baseConfig: any = {
  development: { ...sharedConfig },
  test: { ...sharedConfig },
  preview: { ...sharedConfig },
  staging: { ...sharedConfig },
  production: { ...sharedConfig },
};

export default {
  get: (path: string) => {
    const environment = sharedConfig.environment;
    return baseConfig[environment][path];
  },
  environment: sharedConfig.environment,
};
