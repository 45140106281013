import { computed, Ref } from '@vue/composition-api';
import { handleError } from '@/composables/useErrorHandler';
import { Bill, BillAdd, BillApplyCredits, BillDelete, BillFull, BillNextNo, Bills, BillUpdate, BillView } from '@/graphql/purchases/bill.gql';
// types
import { IFilter, IPager } from '@/types/others/shared';
import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
// remove
import Vue from 'vue';

// queries
const billFetch = ({ billId, queryType = 'default', noCache = false }: IBillAPI) => {
  const queryMap: any = { default: Bill, full: BillFull, view: BillView };
  const {
    result,
    onResult: onBillResult,
    loading,
    refetch: billRefetch,
    error,
  } = useQuery(queryMap[queryType], { id: billId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const bill: any = computed(() => result.value?.bill || { currency: {} });
  return { bill, onBillResult, loading, billRefetch, error };
};

const billLazyFetch = ({ billId, queryType = 'default', noCache = false }: IBillLazyFetchAPI) => {
  const queryMap: any = { default: Bill, full: BillFull, view: BillView };
  const {
    result,
    onResult: onBillResult,
    load: billQueryLoad,
  } = useLazyQuery(queryMap[queryType], { id: billId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const bill = computed(() => result.value?.bill ?? {});
  return { bill, billQueryLoad, onBillResult };
};

const billsFetch = (variables: IBillAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: billsRefetch,
    onResult: onBillsResult,
    error,
  } = useQuery(Bills, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const bills = computed(() => result.value?.bills ?? []);
  return { bills, loading, billsRefetch, onBillsResult, error };
};

const billsLazyFetch = (variables: IBillAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: billsRefetch,
    onResult: onBillsResult,
    load: billsQueryLoad,
    error,
  } = useLazyQuery(Bills, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const bills = computed(() => result.value?.bills ?? []);
  return { bills, loading, billsRefetch, onBillsResult, billsQueryLoad, error };
};

const billNextNoFetch = ({ organizationId }: IBillAPI) => {
  const { result, onResult: onBillNextNo, loading, error } = useQuery(BillNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const billNextNo = computed(() => result.value?.billNextNo ?? []);
  return { documentNextNo: billNextNo, onDocumentNextNo: onBillNextNo, loading, error };
};

// mutations
const billPost = ({ mutationType = 'add', modalNotification = false }: IBillAPI) => {
  const mutationMap: any = {
    add: { mutation: BillAdd, message: 'added' },
    update: { mutation: BillUpdate, message: 'updated' },
    applyCredits: { mutation: BillApplyCredits, message: 'credited' },
    void: { mutation: BillDelete, message: 'voided' },
  };
  const { mutate: postBill, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `Bill successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postBill, loading };
};

export { billFetch, billLazyFetch, billsFetch, billsLazyFetch, billNextNoFetch, billPost };

// types
interface IBillLazyFetchAPI {
  billId?: Ref<string | null>;
  queryType?: string;
  noCache?: boolean;
}
interface IBillFilter extends IFilter {
  accountId: string;
  withDueAmount: boolean;
}
interface IBillAPI {
  organizationId?: string;
  billId?: string;
  pager?: IPager;
  filter?: IBillFilter;
  queryType?: string;
  noCache?: boolean;
  // for post
  data?: any;
  mutationType?: string;
  modalNotification?: boolean;
}
