import { reactive, computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import {
  Organization,
  Organizations,
  OrganizationListItems,
  OrganizationAddListItem,
  OrganizationUpdateListItem,
  OrganizationRemoveListItem,
  OrganizationAdd,
  OrganizationUpdate,
} from '@/graphql/core/organization.gql';
import { handleError } from '@/composables/useErrorHandler';
// remove
import Vue from 'vue';

const organizationFetch = ({ organizationId, noCache = false }: IOrganizationAPI) => {
  const { result, onResult: onOrganizationFetch } = useQuery(
    Organization,
    { id: organizationId },
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first', clientId: 'core' },
  );
  const organization = computed(() => result.value?.organization ?? []);
  return { organization, onOrganizationFetch };
};

const organizationsFetch = () => {
  const {
    result,
    loading,
    refetch: organizationsRefetch,
    onResult: onOrganizationsResult,
    error,
  } = useQuery(Organizations, {}, { clientId: 'core' });
  const organizations = computed(() => result.value?.organizations ?? []);
  return { organizations, loading, organizationsRefetch, onOrganizationsResult, error };
};

const organizationListItemsFetch = ({ organizationId, listItem, fields, noCache = false }: IOrganizationAPI) => {
  // TODO: when listItem is sent, we can only fetch that single item, is there a way
  // to fetch one item alongside others? find out
  const {
    result,
    loading,
    error,
    onResult: onOrgListItemsResult,
    refetch: organizationListItemsRefetch,
  } = useQuery(
    OrganizationListItems,
    {
      id: organizationId,
      listItem,
      fields,
    },
    {
      clientId: 'core',
      fetchPolicy: noCache ? 'no-cache' : 'cache-first',
    },
  );

  const organization = computed(() => result.value?.organization ?? {});
  const users: any = computed(() => result.value?.organization.users ?? {});
  const roles: any = computed(() => result.value?.organization.roles ?? {});
  const taxes: any = computed(() => result.value?.organization?.taxes ?? []);
  const currencies: any = computed(() => result.value?.organization?.currencies ?? []);
  const baseCurrency: any = computed(() => currencies.value[0] ?? {});
  // formatted
  const taxesFormatted = computed(() => taxes.value.map((t: any) => ({ ...t, nameFormatted: `${t.name} [${t.rate}%]` })));
  const currenciesFormatted = computed(() => currencies.value.map((t: any) => ({ ...t, nameFormatted: `${t.name} [${t.rate}%]` })));

  return {
    onOrgListItemsResult,
    organizationListItemsRefetch,
    users,
    roles,
    organization,
    taxes,
    currencies,
    taxesFormatted,
    currenciesFormatted,
    baseCurrency,
    loading,
    error,
  };
};

// mutations
const organizationPost = ({ mutationType = 'add' }: IOrganizationMutationAPI) => {
  const mutationMap: any = {
    add: { mutation: OrganizationAdd, message: 'created' },
    update: { mutation: OrganizationUpdate, message: 'updated' },
  };
  const {
    mutate: postOrganization,
    loading,
    onDone,
    onError,
  } = useMutation(mutationMap[mutationType].mutation, { clientId: 'core', throws: 'always' });
  onDone((result: any) => Vue.prototype.$toastr({ msg: `Organization successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { postOrganization, loading };
};
const organizationListItemsPost = ({ mutationType, itemName }: IOrganizationMutationAPI) => {
  const mutationMap: any = {
    add: { mutation: OrganizationAddListItem, message: 'added' },
    update: { mutation: OrganizationUpdateListItem, message: 'updated' },
    delete: { mutation: OrganizationRemoveListItem, message: 'deleted' },
  };
  const {
    mutate: postOrganizationListItems,
    loading,
    onDone,
    onError,
  } = useMutation(mutationMap[mutationType].mutation, { clientId: 'core', throws: 'always' });
  onDone((result: any) => Vue.prototype.$toastr({ msg: `${itemName} ${mutationMap[mutationType].message} successfully!` }));
  onError((error) => handleError({ error, apollo: true, type: mutationType === 'delete' ? 'toastAlert' : 'formAlert' }));

  return { postOrganizationListItems, loading };
};
export { organizationFetch, organizationsFetch, organizationListItemsFetch, organizationPost, organizationListItemsPost };

// shared btn create & update organization
export const useOrganizationEdit = ({ editOrganization }: IUseOrganizationEdit) => {
  const model: any = reactive({
    name: null,
    location: null,
    default: false,
    industry: null,
    portalName: null,
    inventoryStartDate: new Date(),
    reportBases: 'accrual',
    dateFormat: 'dd/MM/yy',
    fiscalYear: 'January - December',
    timeZone: null,
    language: 'en',
    logo: {},
    currencies: [{ base: true, code: null }],
    taxes: [],
    addresses: editOrganization ? [] : [{ name: 'main', address: null }],
  });

  return {
    model,
  };
};

// document submitting
import { OmitDeep, FormatFields } from '@/utils/sharedMethods';
export const useOrganizationSubmit = ({ model, editOrganization }: IUseOrganizationEdit) => {
  let modelData = Object.assign({}, model);
  const omitFields = ['logo'];
  if (editOrganization) {
    omitFields.push(...['id', 'inventoryStartDate', 'currencies', 'taxes', 'baseCurrency', 'createdAt', 'updatedAt', '__typename']);
  }
  modelData = OmitDeep(modelData, omitFields, [null]);
  modelData = FormatFields(modelData, { toFloat: ['rate'] });

  return {
    modelData,
  };
};

interface IOrganizationAPI {
  organizationId: string;
  listItem?: any;
  fields?: string[];
  noCache?: boolean;
}
interface IOrganizationMutationAPI {
  mutationType: string;
  itemName?: string;
}

interface IUseOrganizationEdit {
  editOrganization: boolean;
  model?: any;
}
