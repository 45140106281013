// tslint:disable:no-console

import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import config from '@/config';
import router from '@/router';
// TS Types`
import { THandleErrors } from './utils/sharedTypes';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'token';

// Config
const defaultOptions = {
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache
  cache: new InMemoryCache(),

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // onCacheInit: (cache: any) => {
  //   cache.writeData({ currentOrganizationId: null })
  // },
  // TODO: Remove this because it is deprecated
  // clientState: {
  //   defaults: {
  //     currentOrganizationId: null,
  //   },
  // },
};

const coreClientOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: config.get('baseUrlCore'),
};

const erpClientOptions = {
  httpEndpoint: config.get('baseUrlErp'),
};

export function createProvider(options = {}) {
  const createCoreClient = createApolloClient({
    ...defaultOptions,
    ...coreClientOptions,
  });

  const createErpClient = createApolloClient({
    ...defaultOptions,
    ...erpClientOptions,
  });

  const core = createCoreClient.apolloClient;
  const erp = createErpClient.apolloClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      core,
      erp,
    },
    defaultClient: erp,
    defaultOptions: {
      $query: {
        // try reading data from your cache first, only fetch from the network if a cached result is not available.
        fetchPolicy: 'cache-first',
      },
    },
    async errorHandler(error) {
      apolloErrorHandler({ error });
    },
  });

  return apolloProvider;
}

// Handle graphQl errors
const consoleLogError = (code: string, message: any) => {
  if (config.environment === 'development') {
    console.log(`%c${code}`, 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', message);
  }
};

export async function apolloErrorHandler({ error, ...otherArgs }: any): Promise<THandleErrors | any> {
  let { message } = otherArgs;
  if (!error && message) {
    return consoleLogError('JS_ERROR', message);
  }
  const { graphQLErrors, networkError } = error || {};
  if (networkError && !Object.keys(networkError).length && !graphQLErrors.length) {
    return Vue.prototype.$toastr({ type: 'error', msg: "Network problem - Can't connect to server!" });
  }
  if (networkError && Object.keys(networkError).length) {
    return Vue.prototype.$toastr({ type: 'error', msg: 'The request failed. Please try again or contact support.' });
  }

  if (graphQLErrors) {
    message = (graphQLErrors[0] || {}).message;
    const { extensions = {} } = graphQLErrors[0];
    switch (extensions.code) {
      case 'UNAUTHENTICATED':
        onLogout(Vue.prototype.$apollo);
        return Vue.prototype.$toastr({ type: 'error', msg: message });
      case 'FORBIDDEN':
        router.push({ name: 'Dashboard' }).catch((err: any) => {});
        return Vue.prototype.$toastr({ type: 'error', msg: message });
      case 'SCHEMA_ERROR':
        return { message };
      case 'BAD_USER_INPUT':
      case 'SERVER_ERROR':
      case 'DB_ERROR':
        if (extensions.readable !== false) {
          // format errorList into an array
          const errorList: string[] = Object.values(extensions.error || {});
          return { message, errorList };
        } else {
          return consoleLogError(extensions.code, message);
        }
      default:
        // includes; GRAPHQL_VALIDATION_FAILED, INTERNAL_SERVER_ERROR
        return consoleLogError(extensions.code, message);
    }
  }
  return;
}

// Manually call this when user log out
export async function onLogout(apolloClient: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
  }
  try {
    await apolloClient.resetStore();
  } catch (e: any) {
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
  router.push({ name: 'Auth' });
}
