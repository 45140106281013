import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { OrganizationData, OrganizationDataUpdate, OrganizationDataDelete } from '@/graphql/others/organizationData.gql';
import { handleError } from '@/composables/useErrorHandler';
// remove
import Vue from 'vue';

// queries
const organizationDataFetch = ({ organizationId, types, noCache = false }: IOrganizationDataAPI) => {
  const {
    result,
    refetch: organizationDataRefetch,
    onResult: onOrganizationDataFetch,
    loading,
    error,
  } = useQuery(
    OrganizationData,
    {
      organizationId,
      types,
    },
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first' },
  );
  const organizationData = computed(() => result.value?.organizationData ?? {});
  const uoms = computed(() => result.value?.organizationData.uoms ?? []);
  const manufacturers = computed(() => result.value?.organizationData.manufacturers ?? []);
  const brands = computed(() => result.value?.organizationData.brands ?? []);
  const paymentTerms = computed(() => result.value?.organizationData.paymentTerms ?? []);
  const paymentModes = computed(() => result.value?.organizationData.paymentModes ?? []);
  const itemAdjustmentReasons = computed(() => result.value?.organizationData?.itemAdjustmentReasons ?? []);

  const settingsInventory = computed(() => result.value?.organizationData.settingsInventory ?? {});
  const availableStockType = computed(() =>
    settingsInventory.value?.general?.stockTrackingMode === 'accountingStock' ? 'accountingAvailableStock' : 'physicalAvailableStock',
  );

  const settingsSystem = computed(() => result.value?.organizationData?.settingsSystem ?? {});
  const settingsSales = computed(() => result.value?.organizationData?.settingsSales ?? {});
  const settingsAccounting = computed(() => result.value?.organizationData?.settingsAccounting ?? {});

  // TODO: sort uoms, manufacturers, brands by name
  // const sortObjectsArray: any = (array: any[] = [], sortBy: string = 'name') => {
  //   return sortObjectsArray(array, sortBy);
  // };

  // TODO: break these down and return only required bits
  return {
    organizationDataRefetch,
    onOrganizationDataFetch,
    organizationData,
    uoms,
    manufacturers,
    brands,
    paymentTerms,
    paymentModes,
    itemAdjustmentReasons,
    settingsInventory,
    availableStockType,
    settingsSystem,
    settingsSales,
    settingsAccounting,
    loading,
    error,
  };
};

// mutations
const organizationDataPost = ({ mutationType = 'add', modalNotification = false, dataName = 'Settings' }: IOrganizationDataPostAPI) => {
  const mutationMap: any = {
    update: { mutation: OrganizationDataUpdate, message: 'updated' },
    delete: { mutation: OrganizationDataDelete, message: 'deleted' },
  };
  const { mutate: postOrganizationData, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `${dataName} successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postOrganizationData, loading };
};

export { organizationDataFetch, organizationDataPost };

interface IOrganizationDataAPI {
  organizationId: string;
  types?: string[];
  noCache?: boolean;
}
interface IOrganizationDataPostAPI {
  mutationType?: string;
  modalNotification?: boolean;
  dataName?: string;
}
