import { computed, Ref } from '@vue/composition-api';
import { useQuery, useLazyQuery, useMutation } from '@vue/apollo-composable';
import {
  Estimate,
  Estimates,
  EstimateAdd,
  EstimateUpdate,
  EstimateDelete,
  EstimateFull,
  EstimateNextNo,
  EstimateView,
} from '@/graphql/sales/estimate.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const estimateFetch = ({ estimateId, queryType = 'default', noCache = false }: IEstimateAPI) => {
  const queryMap: any = {
    default: Estimate,
    full: EstimateFull,
    view: EstimateView,
  };
  const {
    result,
    onResult: onEstimateResult,
    loading,
    error,
  } = useQuery(
    queryMap[queryType],
    {
      id: estimateId,
    },
    { fetchPolicy: noCache ? 'no-cache' : 'cache-first' },
  );
  const estimate = computed(() => result.value?.estimate ?? []);
  return { estimate, onEstimateResult, loading, error };
};

const estimateLazyFetch = ({ estimateId, queryType = 'default', noCache = false }: IEstimateLazyFetchAPI) => {
  const queryMap: any = { default: Estimate, full: EstimateFull, view: EstimateView };
  const {
    result,
    onResult: onEstimateResult,
    load: estimateQueryLoad,
  } = useLazyQuery(queryMap[queryType], { id: estimateId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const estimate = computed(() => result.value?.estimate ?? {});
  return { estimate, estimateQueryLoad, onEstimateResult };
};

const estimatesFetch = (variables: IFilter, noCache = false) => {
  const { result, loading, refetch: estimatesRefetch, error } = useQuery(Estimates, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const estimates = computed(() => result.value?.estimates ?? {});
  return { estimates, estimatesRefetch, loading, error };
};

const estimateNextNoFetch = ({ organizationId }: IEstimateAPI) => {
  const { result, onResult: onEstimateNextNo, loading, error } = useQuery(EstimateNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const estimateNextNo = computed(() => result.value?.estimateNextNo ?? []);
  return { documentNextNo: estimateNextNo, onDocumentNextNo: onEstimateNextNo, loading, error };
};

// mutations
const estimatePost = ({ data, mutationType = 'add', modalNotification = false }: IEstimateAPI) => {
  const mutationMap: any = {
    add: { mutation: EstimateAdd, message: 'added' },
    update: { mutation: EstimateUpdate, message: 'updated' },
    void: { mutation: EstimateDelete, message: 'voided' },
  };
  const { mutate: postEstimate, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Estimate successfully ${mutationMap[mutationType].message}!` });
  });
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postEstimate, loading };
};

export { estimateFetch, estimateLazyFetch, estimatesFetch, estimateNextNoFetch, estimatePost };

interface IEstimateLazyFetchAPI {
  estimateId?: Ref<string | null>;
  queryType?: string;
  noCache?: boolean;
}
interface IFilter {
  status: string[];
  search?: string;
}
interface IEstimateAPI {
  organizationId?: string;
  estimateId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  noCache?: boolean;
  queryType?: string;
  mutationType?: string;
  modalNotification?: boolean;
}
