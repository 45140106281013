import $ from 'jquery';
// == Plugins definition

// bootstrap select
const bootstrapSelect = () => $('.m-bootstrap-select').selectpicker({
  showTick: true,
  size: 6,
});
// datetimepicker
export const datePicker = () => $('.datepicker').datetimepicker({
  format: "dd/mm/yyyy",
  todayHighlight: true,
  autoclose: true,
  startView: 2,
  minView: 2,
  forceParse: 0,
  // startDate
  // endDate
});

// == Class definition
export const mPlugins = function() {
  return {
    init() {
      bootstrapSelect();
    },
    datePickerRefresh() {
      $('.datepicker').datetimepicker('update');
    },
    bootstrapSelectSet(element, value) {
      $(element).selectpicker('val', value);
    },
    bootstrapSelectRefresh() {
      $('.m-bootstrap-select').selectpicker('refresh');
    },
  };
}();
