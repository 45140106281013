import { ref, reactive, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, decimal, minValue } from '@vuelidate/validators';

// main document data
export const usePurchasesDocument = ({ props, root, docName }: IISalesDocument) => {
  const documentName = ref('');
  documentName.value = docName;

  const model: any = reactive({
    organizationId: root.currentOrganizationId,
    accountId: null,
    currencyId: null,
    // branchId: null,
    documentNo: null,
    documentDate: new Date(),
    dueDate: new Date(),
    exchangeRate: 1,
    discountPercentage: true,
    discount: 0,
    otherCharges: 0,
    grandTotal: 0,
    dueAmount: 0,
    discountAmount: 0,
    untaxedSubTotalDiscount: 0,
    subTotal: 0,

    // shared partially
    paymentTerms: 'dueOnReceipt',

    // bill
    paymentMade: 0,
    creditsApplied: 0,
    purchaseOrderId: null,

    // vendorCredit
    amountUsed: 0,
    amountRefunded: 0,
    balance: 0,
    coaId: null,
    billId: null,

    // other shared
    lineItems: [],
    taxes: [],
    options: { otherChargesName: 'Adjustment', taxExclusive: true },
  });
  const v$: any = useVuelidate(
    {
      model: {
        accountId: { required },
        documentNo: { required },
        documentDate: { required },
        exchangeRate: { required, decimal, minValue: minValue(1) },
        referenceNo: {},
      },
    },
    { model },
    { $autoDirty: true, $lazy: true },
  );

  const changeModelTaxType = (isTaxExclusive: boolean) => (model.options.taxExclusive = isTaxExclusive);

  return {
    model,
    v$,
    documentName,
    editDocument: computed(() => !!props.itemId),
    cloneDocumentId: computed(() => root.$route.query.clone),
    changeModelTaxType,
  };
};

// typescript types
interface IISalesDocument {
  props: any;
  root: any;
  docName: string;
}
