import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import {
  Payment,
  PaymentView,
  Payments,
  PaymentsFull,
  PaymentNextNo,
  MpesaPrompt,
  MpesaC2BRegister,
  PaymentAdd,
  PaymentUpdate,
  PaymentReverse,
  PaymentRefund,
  PaymentDelete,
} from '@/graphql/accounting/payment.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

const paymentFetch = ({ paymentId, queryType = 'default', noCache = false }: IPaymentAPI) => {
  const queryMap: any = {
    default: Payment,
    view: PaymentView,
  };
  const {
    result,
    onResult: onPaymentResult,
    refetch: paymentRefetch,
    loading,
    error,
  } = useQuery(queryMap[queryType], { id: paymentId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const payment: any = computed(() => result.value?.payment ?? {});
  return { payment, onPaymentResult, paymentRefetch, loading, error };
};

const paymentsFetch = ({ queryType = 'default', noCache = false, ...variables }: IPaymentAPI) => {
  const queryMap: any = { default: Payments, full: PaymentsFull };
  const {
    result,
    onResult: OnPaymentsResult,
    loading,
    refetch: paymentsRefetch,
    error,
  } = useQuery(queryMap[queryType], variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const payments = computed(() => result.value?.payments ?? []);
  return { payments, OnPaymentsResult, loading, paymentsRefetch, error };
};

const paymentNextNoFetch = ({ organizationId, paymentType }: IPaymentNextNoFetch) => {
  const { result, onResult: onPaymentNextNo, loading, error } = useQuery(PaymentNextNo, { organizationId, paymentType }, { fetchPolicy: 'no-cache' });
  const paymentNextNo = computed(() => result.value?.paymentNextNo ?? []);
  return { documentNextNo: paymentNextNo, onDocumentNextNo: onPaymentNextNo, loading, error };
};

// mutations
const mpesaPrompt = () => {
  const { mutate: promptMpesa, loading, onDone, onError } = useMutation(MpesaPrompt, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: 'Customer successfully sent a mpesa payment request' });
  });
  onError((error) => handleError({ error, apollo: true, type: 'toastAlert' }));

  return { promptMpesa, loading };
};

const mpesaC2BRegisterPost = () => {
  const { mutate: postMpesaC2BRegister, loading, onDone, onError } = useMutation(MpesaC2BRegister, { throws: 'always' });

  onDone(({ data }: any) => {
    Vue.prototype.$toastr({ msg: data?.mpesaC2BRegister?.responseDescription });
  });
  onError((error) => {
    // Vue.prototype.$toastr({ type: 'error', msg: 'There was a problem while attempting to register mpesa urls, check if your configuration is correct' });
    handleError({ error, apollo: true, type: 'toastAlert' });
  });

  return { postMpesaC2BRegister, loading };
};

const paymentPost = ({ data, mutationType = 'add', modalNotification = false }: IPaymentAPI) => {
  const mutationMap: any = {
    add: { mutation: PaymentAdd, message: 'added' },
    update: { mutation: PaymentUpdate, message: 'updated' },
    reverse: { mutation: PaymentReverse, message: 'reversed' },
    refund: { mutation: PaymentRefund, message: 'refunded' },
    refundDelete: { mutation: PaymentRefund, message: 'deleted refund' },
    void: { mutation: PaymentDelete, message: 'voided' },
  };
  const { mutate: postPayment, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => {
    Vue.prototype.$toastr({ msg: `Payment successfully ${mutationMap[mutationType].message}!` });
  });
  // when a payment fails to be created during checkout, we are not proceeding since it can be made later using receive payments
  onError((error) => {
    modalNotification
      ? Vue.prototype.$toastr({ type: 'error', msg: `Payment failed!, proceed to make the invoice payment on received payments section` })
      : handleError({ error, apollo: true, type: 'formAlert' });
  });

  return { postPayment, loading };
};

// other functions
// for mpesa or others
import { DateFormat } from '@/utils/sharedMethods';
const updatePaymentDetails = (paymentMade: any, payment: any, dueAmount: number = 0) => {
  const { id, amount, balance = 0, transactionDate, transactionNo, status, modeDetails, documentNo } = paymentMade;
  Object.assign(payment, {
    id,
    amount: balance,
    balance,
    amountUsed: balance > dueAmount ? dueAmount : balance,
    transactionDate,
    transactionNo,
    status,
    modeDetails,
  });
  if (documentNo) payment.documentNo = documentNo;
};

export { paymentFetch, paymentsFetch, paymentNextNoFetch, mpesaPrompt, mpesaC2BRegisterPost, paymentPost, updatePaymentDetails };

// types
interface IMpesaQuery {
  shortCode?: string;
  phoneNo?: string;
  transactionNo?: string;
}
interface IPaymentFilter {
  mpesaQuery?: IMpesaQuery;
}
interface IPaymentNextNoFetch {
  organizationId: string;
  paymentType: string; // vendor or customer
}
interface IPaymentAPI {
  organizationId?: string;
  paymentId?: string;
  pager?: IPager;
  filter?: IPaymentFilter;
  data?: any;
  queryType?: string;
  noCache?: boolean;
  mutationType?: string;
  modalNotification?: boolean;
}
