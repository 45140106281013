import mUtil from '../base/util';

var mMenu = function(elementId, options) {
    //== Main object
    var the = this;
    var init = false;

    //== Get element object
    var element = mUtil.get(elementId);
    var body = mUtil.get('body');  

    if (!element) {
        return;
    }

    //== Default options
    var defaultOptions = {       
        // accordion submenu mode
        accordion: {
            slideSpeed: 200, // accordion toggle slide speed in milliseconds
            autoScroll: false, // enable auto scrolling(focus) to the clicked menu item
            autoScrollSpeed: 1200,
            expandAll: true // allow having multiple expanded accordions in the menu
        },

        // dropdown submenu mode
        dropdown: {
            timeout: 500 // timeout in milliseconds to show and hide the hoverable submenu dropdown
        }
    };

    ////////////////////////////
    // ** Private Methods  ** //
    ////////////////////////////

    var Plugin = {
        /**
         * Run plugin
         * @returns {mMenu}
         */
        construct: function(options) {
            if (mUtil.data(element).has('menu')) {
                the = mUtil.data(element).get('menu');
            } else {
                // reset menu
                Plugin.init(options);

                // reset menu
                Plugin.reset();

                // build menu
                Plugin.build();

                mUtil.data(element).set('menu', the);
            }

            return the;
        },

        /**
         * Handles submenu click toggle
         * @returns {mMenu}
         */
        init: function(options) {
            the.events = [];

            the.eventHandlers = {};

            // merge default and user defined options
            the.options = mUtil.deepExtend({}, defaultOptions, options);

            // pause menu
            the.pauseDropdownHoverTime = 0;

            the.uid = mUtil.getUniqueID();
        },

        update: function(options) {
            // merge default and user defined options
            the.options = mUtil.deepExtend({}, defaultOptions, options);

            // pause menu
            the.pauseDropdownHoverTime = 0;

             // reset menu
            Plugin.reset();

            the.eventHandlers = {};

            // build menu
            Plugin.build();

            mUtil.data(element).set('menu', the);
        },

        reload: function() {
             // reset menu
            Plugin.reset();

            // build menu
            Plugin.build();
        },

        /**
         * Reset menu
         * @returns {mMenu}
         */
        build: function() {
            //== General accordion submenu toggle
            the.eventHandlers['event_1'] = mUtil.on( element, '.m-menu__toggle', 'click', Plugin.handleSubmenuAccordion);

            //== Dropdown mode(hoverable)
            if (Plugin.getSubmenuMode() === 'dropdown' || Plugin.isConditionalSubmenuDropdown()) {
                // dropdown submenu - hover toggle
                the.eventHandlers['event_2'] = mUtil.on( element, '[m-menu-submenu-toggle="hover"]', 'mouseover', Plugin.handleSubmenuDrodownHoverEnter);
                the.eventHandlers['event_3'] = mUtil.on( element, '[m-menu-submenu-toggle="hover"]', 'mouseout', Plugin.handleSubmenuDrodownHoverExit);

                // dropdown submenu - click toggle
                the.eventHandlers['event_4'] = mUtil.on( element, '[m-menu-submenu-toggle="click"] > .m-menu__toggle, [m-menu-submenu-toggle="click"] > .m-menu__link .m-menu__toggle', 'click', Plugin.handleSubmenuDropdownClick);
                the.eventHandlers['event_5'] = mUtil.on( element, '[m-menu-submenu-toggle="tab"] > .m-menu__toggle, [m-menu-submenu-toggle="tab"] > .m-menu__link .m-menu__toggle', 'click', Plugin.handleSubmenuDropdownTabClick);
            }

            //== General link click
            the.eventHandlers['event_6'] = mUtil.on(element, '.m-menu__item:not(.m-menu__item--submenu) > .m-menu__link:not(.m-menu__toggle):not(.m-menu__link--toggle-skip)', 'click', Plugin.handleLinkClick);

            //== Init scrollable menu
            if (the.options.scroll && the.options.scroll.height) {
                Plugin.scrollerInit();
            }
        },

        /**
         * Reset menu
         * @returns {mMenu}
         */
        reset: function() { 
            mUtil.off( element, 'click', the.eventHandlers['event_1']);

            // dropdown submenu - hover toggle
            mUtil.off( element, 'mouseover', the.eventHandlers['event_2']);
            mUtil.off( element, 'mouseout', the.eventHandlers['event_3']);

            // dropdown submenu - click toggle
            mUtil.off( element, 'click', the.eventHandlers['event_4']);
            mUtil.off( element, 'click', the.eventHandlers['event_5']);
            
            mUtil.off(element, 'click', the.eventHandlers['event_6']);
        },

        /**
         * Init scroll menu
         *
        */
        scrollerInit: function() {
            if ( the.options.scroll && the.options.scroll.height ) {
                mUtil.scrollerDestroy(element);
                mUtil.scrollerInit(element, {disableForMobile: true, resetHeightOnDestroy: true, handleWindowResize: true, height: the.options.scroll.height});
            }            
        },

        /**
         * Update scroll menu
        */
        scrollerUpdate: function() {
            if ( the.options.scroll && the.options.scroll.height ) {
                mUtil.scrollerUpdate(element);
            } else {
                mUtil.scrollerDestroy(element);
            }
        },

        /**
         * Scroll top
        */
        scrollerTop: function() {
            if ( the.options.scroll && the.options.scroll.height ) {
                mUtil.scrollerTop(element);
            }
        },

        /**
         * Get submenu mode for current breakpoint and menu state
         * @returns {mMenu}
         */
        getSubmenuMode: function(el) {
            if ( mUtil.isInResponsiveRange('desktop') ) {
                if (el && mUtil.hasAttr(el, 'm-menu-submenu-toggle')) {
                    return mUtil.attr(el, 'm-menu-submenu-toggle');
                }

                if ( mUtil.isset(the.options.submenu, 'desktop.state.body') ) {
                    if ( mUtil.hasClass(body, the.options.submenu.desktop.state.body) ) {
                        return the.options.submenu.desktop.state.mode;
                    } else {
                        return the.options.submenu.desktop.default;
                    }
                } else if ( mUtil.isset(the.options.submenu, 'desktop') ) {
                    return the.options.submenu.desktop;
                }
            } else if ( mUtil.isInResponsiveRange('tablet') && mUtil.isset(the.options.submenu, 'tablet') ) {
                return the.options.submenu.tablet;
            } else if ( mUtil.isInResponsiveRange('mobile') && mUtil.isset(the.options.submenu, 'mobile') ) {
                return the.options.submenu.mobile;
            } else {
                return false;
            }
        },

        /**
         * Get submenu mode for current breakpoint and menu state
         * @returns {mMenu}
         */
        isConditionalSubmenuDropdown: function() {
            if ( mUtil.isInResponsiveRange('desktop') && mUtil.isset(the.options.submenu, 'desktop.state.body') ) {
                return true;
            } else {
                return false;
            }
        },

        /**
         * Handles menu link click
         * @returns {mMenu}
         */
        handleLinkClick: function(e) {
            if ( Plugin.eventTrigger('linkClick', this) === false ) {
                e.preventDefault();
            };

            if ( Plugin.getSubmenuMode(this) === 'dropdown' || Plugin.isConditionalSubmenuDropdown() ) {
                Plugin.handleSubmenuDropdownClose(e, this);
            }
        },

        /**
         * Handles submenu hover toggle
         * @returns {mMenu}
         */
        handleSubmenuDrodownHoverEnter: function(e) {
            if ( Plugin.getSubmenuMode(this) === 'accordion' ) {
                return;
            }

            if ( the.resumeDropdownHover() === false ) {
                return;
            }

            var item = this;

            if ( item.getAttribute('data-hover') == '1' ) {
                item.removeAttribute('data-hover');
                clearTimeout( item.getAttribute('data-timeout') );
                item.removeAttribute('data-timeout');
                //Plugin.hideSubmenuDropdown(item, false);
            }

            Plugin.showSubmenuDropdown(item);
        },

        /**
         * Handles submenu hover toggle
         * @returns {mMenu}
         */
        handleSubmenuDrodownHoverExit: function(e) {
            if ( the.resumeDropdownHover() === false ) {
                return;
            }

            if ( Plugin.getSubmenuMode(this) === 'accordion' ) {
                return;
            }

            var item = this;
            var time = the.options.dropdown.timeout;

            var timeout = setTimeout(function() {
                if ( item.getAttribute('data-hover') == '1' ) {
                    Plugin.hideSubmenuDropdown(item, true);
                } 
            }, time);

            item.setAttribute('data-hover', '1');
            item.setAttribute('data-timeout', timeout);  
        },

        /**
         * Handles submenu click toggle
         * @returns {mMenu}
         */
        handleSubmenuDropdownClick: function(e) {
            if ( Plugin.getSubmenuMode(this) === 'accordion' ) {
                return;
            }
 
            var item = this.closest('.m-menu__item'); 

            if ( item.getAttribute('m-menu-submenu-mode') == 'accordion' ) {
                return;
            }

            if ( mUtil.hasClass(item, 'm-menu__item--hover') === false ) {
                mUtil.addClass(item, 'm-menu__item--open-dropdown');
                Plugin.showSubmenuDropdown(item);
            } else {
                mUtil.removeClass(item, 'm-menu__item--open-dropdown' );
                Plugin.hideSubmenuDropdown(item, true);
            }

            e.preventDefault();
        },

        /**
         * Handles tab click toggle
         * @returns {mMenu}
         */
        handleSubmenuDropdownTabClick: function(e) {
            if (Plugin.getSubmenuMode(this) === 'accordion') {
                return;
            }

            var item = this.closest('.m-menu__item');

            if (item.getAttribute('m-menu-submenu-mode') == 'accordion') {
                return;
            }

            if (mUtil.hasClass(item, 'm-menu__item--hover') == false) {
                mUtil.addClass(item, 'm-menu__item--open-dropdown');
                Plugin.showSubmenuDropdown(item);
            }

            e.preventDefault();
        },

        /**
         * Handles submenu dropdown close on link click
         * @returns {mMenu}
         */
        handleSubmenuDropdownClose: function(e, el) {
            // exit if its not submenu dropdown mode
            if (Plugin.getSubmenuMode(el) === 'accordion') {
                return;
            }

            var shown = element.querySelectorAll('.m-menu__item.m-menu__item--submenu.m-menu__item--hover:not(.m-menu__item--tabs)');

            // check if currently clicked link's parent item ha
            if (shown.length > 0 && mUtil.hasClass(el, 'm-menu__toggle') === false && el.querySelectorAll('.m-menu__toggle').length === 0) {
                // close opened dropdown menus
                for (var i = 0, len = shown.length; i < len; i++) {
                    Plugin.hideSubmenuDropdown(shown[0], true);
                }
            }
        },

        /**
         * helper functions
         * @returns {mMenu}
         */
        handleSubmenuAccordion: function(e, el) {
            var query;
            var item = el ? el : this;

            if ( Plugin.getSubmenuMode(el) === 'dropdown' && (query = item.closest('.m-menu__item') ) ) {
                if (query.getAttribute('m-menu-submenu-mode') != 'accordion' ) {
                    e.preventDefault();
                    return;
                }
            }

            var li = item.closest('.m-menu__item');
            var submenu = mUtil.child(li, '.m-menu__submenu, .m-menu__inner');

            if (mUtil.hasClass(item.closest('.m-menu__item'), 'm-menu__item--open-always')) {
                return;
            }

            if ( li && submenu ) {
                e.preventDefault();
                var speed = the.options.accordion.slideSpeed;
                var hasClosables = false;

                if ( mUtil.hasClass(li, 'm-menu__item--open') === false ) {
                    // hide other accordions                    
                    if ( the.options.accordion.expandAll === false ) {
                        var subnav = item.closest('.m-menu__nav, .m-menu__subnav');
                        var closables = mUtil.children(subnav, '.m-menu__item.m-menu__item--open.m-menu__item--submenu:not(.m-menu__item--expanded):not(.m-menu__item--open-always)');

                        if ( subnav && closables ) {
                            for (var i = 0, len = closables.length; i < len; i++) {
                                var el_ = closables[0];
                                var submenu_ = mUtil.child(el_, '.m-menu__submenu');
                                if ( submenu_ ) {
                                    mUtil.slideUp(submenu_, speed, function() {
                                        Plugin.scrollerUpdate();
                                        mUtil.removeClass(el_, 'm-menu__item--open');
                                    });                    
                                }
                            }
                        }
                    }

                    mUtil.slideDown(submenu, speed, function() {
                        Plugin.scrollToItem(item);
                        Plugin.scrollerUpdate();
                        
                        Plugin.eventTrigger('submenuToggle', submenu);
                    });
                
                    mUtil.addClass(li, 'm-menu__item--open');

                } else {
                    mUtil.slideUp(submenu, speed, function() {
                        Plugin.scrollToItem(item);
                        Plugin.eventTrigger('submenuToggle', submenu);
                    });

                    mUtil.removeClass(li, 'm-menu__item--open');       
                }
            }
        },

        /**
         * scroll to item function
         * @returns {mMenu}
         */
        scrollToItem: function(item) {
            // handle auto scroll for accordion submenus
            if ( mUtil.isInResponsiveRange('desktop') && the.options.accordion.autoScroll && element.getAttribute('m-menu-scrollable') !== '1' ) {
                mUtil.scrollTo(item, the.options.accordion.autoScrollSpeed);
            }
        },

        /**
         * helper functions
         * @returns {mMenu}
         */
        hideSubmenuDropdown: function(item, classAlso) {
            // remove submenu activation class
            if ( classAlso ) {
                mUtil.removeClass(item, 'm-menu__item--hover');
                mUtil.removeClass(item, 'm-menu__item--active-tab');
            }

            // clear timeout
            item.removeAttribute('data-hover');

            if ( item.getAttribute('m-menu-dropdown-toggle-class') ) {
                mUtil.removeClass(body, item.getAttribute('m-menu-dropdown-toggle-class'));
            }

            var timeout = item.getAttribute('data-timeout');
            item.removeAttribute('data-timeout');
            clearTimeout(timeout);
        },

        /**
         * helper functions
         * @returns {mMenu}
         */
        showSubmenuDropdown: function(item) {
            // close active submenus
            var list = element.querySelectorAll('.m-menu__item--submenu.m-menu__item--hover, .m-menu__item--submenu.m-menu__item--active-tab');

            if ( list ) {
                for (var i = 0, len = list.length; i < len; i++) {
                    var el = list[i];
                    if ( item !== el && el.contains(item) === false && item.contains(el) === false ) {
                        Plugin.hideSubmenuDropdown(el, true);
                    }
                }
            } 

            // adjust submenu position
            Plugin.adjustSubmenuDropdownArrowPos(item);

            // add submenu activation class
            mUtil.addClass(item, 'm-menu__item--hover');
            
            if ( item.getAttribute('m-menu-dropdown-toggle-class') ) {
                mUtil.addClass(body, item.getAttribute('m-menu-dropdown-toggle-class'));
            }
        },

        /**
         * Handles submenu slide toggle
         * @returns {mMenu}
         */
        createSubmenuDropdownClickDropoff: function(el) {
            var query;
            var zIndex = (query = mUtil.child(el, '.m-menu__submenu') ? mUtil.css(query, 'z-index') : 0) - 1;

            var dropoff = document.createElement('<div class="m-menu__dropoff" style="background: transparent; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: ' + zIndex + '"></div>');

            body.appendChild(dropoff);

            mUtil.addEvent(dropoff, 'click', function(e) {
                e.stopPropagation();
                e.preventDefault();
                mUtil.remove(this);
                Plugin.hideSubmenuDropdown(el, true);
            });
        },

        /**
         * Handles submenu click toggle
         * @returns {mMenu}
         */
        adjustSubmenuDropdownArrowPos: function(item) {
            var submenu = mUtil.child(item, '.m-menu__submenu');
            var arrow = mUtil.child( submenu, '.m-menu__arrow.m-menu__arrow--adjust');
            var subnav = mUtil.child( submenu, '.m-menu__subnav');

            if ( arrow ) { 
                var pos = 0; 
                var link = mUtil.child(item, '.m-menu__link');

                if ( mUtil.hasClass(submenu, 'm-menu__submenu--classic') || mUtil.hasClass(submenu, 'm-menu__submenu--fixed') ) {
                    if ( mUtil.hasClass(submenu, 'm-menu__submenu--right')) {
                        pos = mUtil.outerWidth(item) / 2;
                        if (mUtil.hasClass(submenu, 'm-menu__submenu--pull')) {
                            if (mUtil.isRTL()) {
                                pos = pos + Math.abs( parseFloat(mUtil.css(submenu, 'margin-left')) );
                            } else {
                                pos = pos + Math.abs( parseFloat(mUtil.css(submenu, 'margin-right')) );
                            }
                        }
                        pos = parseInt(mUtil.css(submenu, 'width')) - pos;
                    } else if ( mUtil.hasClass(submenu, 'm-menu__submenu--left') ) {
                        pos = mUtil.outerWidth(item) / 2;
                        if ( mUtil.hasClass(submenu, 'm-menu__submenu--pull')) {
                            if (mUtil.isRTL()) {
                                pos = pos + Math.abs( parseFloat(mUtil.css(submenu, 'margin-right')) );
                            } else {
                                pos = pos + Math.abs( parseFloat(mUtil.css(submenu, 'margin-left')) );
                            }
                        }
                    }

                    if (mUtil.isRTL()) {
                        mUtil.css(arrow, 'right', pos + 'px');  
                    } else {
                        mUtil.css(arrow, 'left', pos + 'px');  
                    }
                } else {
                    if ( mUtil.hasClass(submenu, 'm-menu__submenu--center') || mUtil.hasClass(submenu, 'm-menu__submenu--full') ) {
                        pos = mUtil.offset(item).left - ((mUtil.getViewPort().width - parseInt(mUtil.css(submenu, 'width'))) / 2);
                        pos = pos + (mUtil.outerWidth(item) / 2);

                        mUtil.css(arrow, 'left', pos + 'px');
                        if (mUtil.isRTL()) {
                            mUtil.css(arrow, 'right', 'auto');
                        }                        
                    }
                }
            }
        },

        /**
         * Handles submenu hover toggle
         * @returns {mMenu}
         */
        pauseDropdownHover: function(time) {
            var date = new Date();

            the.pauseDropdownHoverTime = date.getTime() + time;
        },

        /**
         * Handles submenu hover toggle
         * @returns {mMenu}
         */
        resumeDropdownHover: function() {
            var date = new Date();

            return (date.getTime() > the.pauseDropdownHoverTime ? true : false);
        },

        /**
         * Reset menu's current active item
         * @returns {mMenu}
         */
        resetActiveItem: function(item) {
            var list;
            var parents;

            list = element.querySelectorAll('.m-menu__item--active');
            
            for (var i = 0, len = list.length; i < len; i++) {
                var el = list[0];
                mUtil.removeClass(el, 'm-menu__item--active');
                mUtil.hide( mUtil.child(el, '.m-menu__submenu') );
                parents = mUtil.parents(el, '.m-menu__item--submenu');

                for (var i_ = 0, len_ = parents.length; i_ < len_; i_++) {
                    var el_ = parents[i];
                    mUtil.removeClass(el_, 'm-menu__item--open');
                    mUtil.hide( mUtil.child(el_, '.m-menu__submenu') );
                }
            }

            // close open submenus
            if ( the.options.accordion.expandAll === false ) {
                if ( list = element.querySelectorAll('.m-menu__item--open') ) {
                    for (var i = 0, len = list.length; i < len; i++) {
                        mUtil.removeClass(parents[0], 'm-menu__item--open');
                    }
                }
            }
        },

        /**
         * Sets menu's active item
         * @returns {mMenu}
         */
        setActiveItem: function(item) {
            // reset current active item
            Plugin.resetActiveItem();

            mUtil.addClass(item, 'm-menu__item--active');
            
            var parents = mUtil.parents(item, '.m-menu__item--submenu');
            for (var i = 0, len = parents.length; i < len; i++) {
                mUtil.addClass(parents[i], 'm-menu__item--open');
            }
        },

        /**
         * Returns page breadcrumbs for the menu's active item
         * @returns {mMenu}
         */
        getBreadcrumbs: function(item) {
            var query;
            var breadcrumbs = [];
            var link = mUtil.child(item, '.m-menu__link');

            breadcrumbs.push({
                text: (query = mUtil.child(link, '.m-menu__link-text') ? query.innerHTML : ''),
                title: link.getAttribute('title'),
                href: link.getAttribute('href')
            });

            var parents = mUtil.parents(item, '.m-menu__item--submenu');
            for (var i = 0, len = parents.length; i < len; i++) {
                var submenuLink = mUtil.child(parents[i], '.m-menu__link');

                breadcrumbs.push({
                    text: (query = mUtil.child(submenuLink, '.m-menu__link-text') ? query.innerHTML : ''),
                    title: submenuLink.getAttribute('title'),
                    href: submenuLink.getAttribute('href')
                });
            }

            return  breadcrumbs.reverse();
        },

        /**
         * Returns page title for the menu's active item
         * @returns {mMenu}
         */
        getPageTitle: function(item) {
            var query;

            return (query = mUtil.child(item, '.m-menu__link-text') ? query.innerHTML : '');
        },

        /**
         * Trigger events
         */
        eventTrigger: function(name, args) {
            for (var i = 0; i < the.events.length; i++ ) {
                var event = the.events[i];
                if ( event.name == name ) {
                    if ( event.one == true ) {
                        if ( event.fired == false ) {
                            the.events[i].fired = true;
                            event.handler.call(this, the, args);
                        }
                    } else {
                        event.handler.call(this, the, args);
                    }
                }
            }
        },

        addEvent: function(name, handler, one) {
            the.events.push({
                name: name,
                handler: handler,
                one: one,
                fired: false
            });
        },

        removeEvent: function(name) {
            if (the.events[name]) {
                delete the.events[name];
            }
        }
    };

    //////////////////////////
    // ** Public Methods ** //
    //////////////////////////

    /**
     * Set default options 
     */

    the.setDefaults = function(options) {
        defaultOptions = options;
    };

    /**
     * Set active menu item
     */
    the.scrollerUpdate = function() {
        return Plugin.scrollerUpdate();
    };

    /**
     * Set active menu item
     */
    the.scrollerTop = function() {
        return Plugin.scrollerTop();
    };

    /**
     * Set active menu item
     */
    the.setActiveItem = function(item) {
        return Plugin.setActiveItem(item);
    };

    the.reload = function() {
        return Plugin.reload();
    };

    the.update = function(options) {
        return Plugin.update(options);
    };

    /**
     * Set breadcrumb for menu item
     */
    the.getBreadcrumbs = function(item) {
        return Plugin.getBreadcrumbs(item);
    };

    /**
     * Set page title for menu item
     */
    the.getPageTitle = function(item) {
        return Plugin.getPageTitle(item);
    };

    /**
     * Get submenu mode
     */
    the.getSubmenuMode = function(el) {
        return Plugin.getSubmenuMode(el);
    };

    /**
     * Hide dropdown submenu
     * @returns {jQuery}
     */
    the.hideDropdown = function(item) {
        Plugin.hideSubmenuDropdown(item, true);
    };

    /**
     * Disable menu for given time
     * @returns {jQuery}
     */
    the.pauseDropdownHover = function(time) {
        Plugin.pauseDropdownHover(time);
    };

    /**
     * Disable menu for given time
     * @returns {jQuery}
     */
    the.resumeDropdownHover = function() {
        return Plugin.resumeDropdownHover();
    };

    /**
     * Register event
     */
    the.on = function(name, handler) {
        return Plugin.addEvent(name, handler);
    };

    the.off = function(name) {
        return Plugin.removeEvent(name);
    };

    the.one = function(name, handler) {
        return Plugin.addEvent(name, handler, true);
    };

    ///////////////////////////////
    // ** Plugin Construction ** //
    ///////////////////////////////

    //== Run plugin
    Plugin.construct.apply(the, [options]);

    //== Handle plugin on window resize
    mUtil.addResizeHandler(function() {
        if (init) {
            the.reload();
        }  
    });

    //== Init done
    init = true;

    // Return plugin instance
    return the;
};

// Plugin global lazy initialization
document.addEventListener("click", function (e) {
    var body = mUtil.get('body');
    var query;
    if ( query = body.querySelectorAll('.m-menu__nav .m-menu__item.m-menu__item--submenu.m-menu__item--hover:not(.m-menu__item--tabs)[m-menu-submenu-toggle="click"]') ) {
        for (var i = 0, len = query.length; i < len; i++) {
            var element = query[i].closest('.m-menu__nav').parentNode;

            if ( element ) {
                var the = mUtil.data(element).get('menu');

                if ( !the ) {
                    break;
                }

                if ( !the || the.getSubmenuMode() !== 'dropdown' ) {
                    break;
                }

                if ( e.target !== element && element.contains(e.target) === false ) {
                    var items;
                    if ( items = element.querySelectorAll('.m-menu__item--submenu.m-menu__item--hover:not(.m-menu__item--tabs)[m-menu-submenu-toggle="click"]') ) {
                        for (var j = 0, cnt = items.length; j < cnt; j++) {
                            the.hideDropdown(items[j]);
                        }
                    }
                }
            }            
        }
    } 
});

export default mMenu;
