import { computed, Ref } from '@vue/composition-api';
import { handleError } from '@/composables/useErrorHandler';
import {
  Invoice,
  InvoiceAdd,
  InvoiceApplyCredits,
  InvoiceDelete,
  InvoiceFull,
  InvoiceNextNo,
  Invoices,
  InvoiceUpdate,
  InvoiceCheckout,
  InvoiceView,
} from '@/graphql/sales/invoice.gql';
// types
import { IFilter, IPager } from '@/types/others/shared';
import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
// remove
import Vue from 'vue';

// queries
const invoiceFetch = ({ invoiceId, queryType = 'default', noCache = false }: IInvoiceAPI) => {
  const queryMap: any = { default: Invoice, full: InvoiceFull, view: InvoiceView };
  const {
    result,
    onResult: onInvoiceResult,
    loading,
    refetch: invoiceRefetch,
    error,
  } = useQuery(queryMap[queryType], { id: invoiceId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const invoice: any = computed(() => result.value?.invoice || { currency: {} });
  return { invoice, onInvoiceResult, loading, invoiceRefetch, error };
};

const invoiceLazyFetch = ({ invoiceId, queryType = 'default', noCache = false }: IInvoiceLazyFetchAPI) => {
  const queryMap: any = { default: Invoice, full: InvoiceFull, view: InvoiceView };
  const {
    result,
    onResult: onInvoiceResult,
    load: invoiceQueryLoad,
  } = useLazyQuery(queryMap[queryType], { id: invoiceId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const invoice = computed(() => result.value?.invoice ?? {});

  return { invoice, invoiceQueryLoad, onInvoiceResult };
};

const invoicesFetch = (variables: IInvoiceAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: invoicesRefetch,
    onResult: onInvoicesResult,
    error,
  } = useQuery(Invoices, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const invoices = computed(() => result.value?.invoices ?? []);
  return { invoices, loading, invoicesRefetch, onInvoicesResult, error };
};

const invoicesLazyFetch = (variables: IInvoiceAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: invoicesRefetch,
    onResult: onInvoicesResult,
    load: invoicesQueryLoad,
    error,
  } = useLazyQuery(Invoices, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const invoices = computed(() => result.value?.invoices ?? []);
  return { invoices, loading, invoicesRefetch, onInvoicesResult, invoicesQueryLoad, error };
};

const invoiceNextNoFetch = ({ organizationId }: IInvoiceAPI) => {
  const { result, onResult: onInvoiceNextNo, loading, error } = useQuery(InvoiceNextNo, { organizationId }, { fetchPolicy: 'no-cache' });
  const invoiceNextNo = computed(() => result.value?.invoiceNextNo ?? []);
  return { documentNextNo: invoiceNextNo, onDocumentNextNo: onInvoiceNextNo, loading, error };
};

// mutations
const invoicePost = ({ mutationType = 'add', modalNotification = false }: IInvoiceAPI) => {
  const mutationMap: any = {
    add: { mutation: InvoiceAdd, message: 'added' },
    update: { mutation: InvoiceUpdate, message: 'updated' },
    checkout: { mutation: InvoiceCheckout, message: 'checked out' },
    applyCredits: { mutation: InvoiceApplyCredits, message: 'credited' },
    void: { mutation: InvoiceDelete, message: 'voided' },
  };
  const { mutate: postInvoice, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `Invoice successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postInvoice, loading };
};

export { invoiceFetch, invoiceLazyFetch, invoicesFetch, invoicesLazyFetch, invoiceNextNoFetch, invoicePost };

// types
interface IInvoiceLazyFetchAPI {
  invoiceId?: Ref<string | null>;
  queryType?: string;
  noCache?: boolean;
}
interface IInvoiceFilter extends IFilter {
  accountId: string;
  withDueAmount: boolean;
}
interface IInvoiceAPI {
  organizationId?: string;
  invoiceId?: string;
  pager?: IPager;
  filter?: IInvoiceFilter;
  queryType?: string;
  noCache?: boolean;
  // for post
  data?: any;
  mutationType?: string;
  modalNotification?: boolean;
}
