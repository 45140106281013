/* tslint:disable:no-console */

import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import config from '@/config';
// remove
import router from '@/router';
import Vue from 'vue';

const AUTH_TOKEN = 'token';

const coreHttpLink = createHttpLink({
  uri: config.get('baseUrlCore'),
});
const erpHttpLink = createHttpLink({
  uri: config.get('baseUrlErp'),
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);
  const organization_id = localStorage.getItem('currentOrganizationId');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      organization_id,
    },
  };
});

const errorLink = onError((error: any) => {
  const { graphQLErrors, networkError } = error;

  if (networkError && !graphQLErrors) {
    return Vue.prototype.$toastr({ type: 'error', msg: "Network problem - Can't connect to server!" });
  }
  const consoleLogError = () => {
    if (process.env.NODE_ENV !== 'production') logErrorMessages(error);
  };

  if (graphQLErrors) {
    const message = (graphQLErrors[0] || {}).message;
    const { extensions = {} } = graphQLErrors[0];
    switch (extensions.code) {
      case 'UNAUTHENTICATED':
        onLogout();
        return Vue.prototype.$toastr({ type: 'error', msg: message });
      case 'FORBIDDEN':
        router.push({ name: 'Dashboard' }).catch((err: any) => {});
        return Vue.prototype.$toastr({ type: 'error', msg: message });
      case 'SCHEMA_ERROR':
        return { message };
      case 'BAD_USER_INPUT':
      case 'SERVER_ERROR':
      case 'DB_ERROR':
        // the error will be returned and get handled somewhere else
        if (extensions.readable !== false) return;

        return consoleLogError();
      default:
        // includes; GRAPHQL_VALIDATION_FAILED, INTERNAL_SERVER_ERROR
        return consoleLogError();
    }
  }
});

export const onLogout = async () => {
  try {
    // remove all including the organizationId
    localStorage.clear();

    // no need for await for these ones
    apolloCoreClient.resetStore();
    apolloErpClient.resetStore();
    router.push({ name: 'Auth' });
  } catch (e: any) {
    if (process.env.NODE_ENV !== 'production') console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
};

export const apolloCoreClient = new ApolloClient({
  name: 'core',
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, coreHttpLink]),
});

export const apolloErpClient = new ApolloClient({
  name: 'erp',
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, erpHttpLink]),
});
