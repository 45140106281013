const loadingBtnEnable = (btn: any) => {
  btn.disabled = true;
  btn.classList.add('m-loader', 'm-loader--right', 'm-loader--light');
};

const loadingBtnDisable = (btn: any) => {
  btn.disabled = false;
  btn.classList.remove('m-loader', 'm-loader--right', 'm-loader--light');
};

export { loadingBtnEnable, loadingBtnDisable };
