import config from '@/config';
import { handleError } from '@/composables/useErrorHandler';
import { CloudinarySignRequest } from '@/graphql/core/services.gql';
import { useMutation } from '@vue/apollo-composable';

// mutations
const cloudinarySignRequestPost = () => {
  const { mutate: postCloudinarySignRequest, loading, onDone, onError } = useMutation(CloudinarySignRequest, { throws: 'always' });
  // onDone((result: any) => Vue.prototype.$toastr({ msg: `${dataName} successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { postCloudinarySignRequest, loading };
};

// Shared in upload places
import { organizationPost } from '@/composables/core/useOrganizationAPI';
const { postOrganization } = organizationPost({ mutationType: 'update' });
const { postCloudinarySignRequest: genCloudinarySignature } = cloudinarySignRequestPost();
export const useFileUploader = () => {
  let uploadWidget: any;

  const cloudinaryGenSignature = async ({ callback, paramsToSign }: ICloudinarySignRequestPost) => {
    genCloudinarySignature({ data: paramsToSign })
      .then((result: any) => callback(result.cloudinarySignRequest))
      .catch(() => {});
  };
  // image uploader
  const setupUploadWidget = async ({ folder, multiple = false, cropping = false, currentOrganizationId }: IFileUpload) => {
    const envName = config.environment === 'production' ? 'prod' : 'dev';
    const widgetParams = {
      cloudName: 'upscaleerp',
      apiKey: config.get('cloudinaryApiKey'),
      uploadPreset: `upscaleerp_${envName}`,
      uploadSignature: cloudinaryGenSignature,
      folder: `${envName}/${folder}`,
      sources: ['local', 'google_drive', 'dropbox', 'camera', 'image_search', 'url'],
      googleApiKey: '<image_search_google_api_key>',
      showAdvancedOptions: false,
      cropping,
      // maxFiles
      multiple,
      defaultSource: 'local',
      // image params
      clientAllowedFormats: ['png', 'gif', 'jpeg'],
      maxImageFileSize: 1000000,
      // maxVideoFileSize: 1000000,
      // maxRawFileSize: 1000000,
      // image params end
      styles: {
        palette: {
          window: '#FAF9FC',
          sourceBg: '#FFFFFF',
          windowBorder: '#EBEDF2',
          tabIcon: '#716ACA',
          inactiveTabIcon: '#898B96',
          menuIcons: '#E2E8EA',
          link: '#716ACA',
          action: '#00C5DC',
          inProgress: '#0194c7',
          complete: '#716ACA',
          error: '#c43737',
          textDark: '#FFFFFF',
          textLight: '#898B96',
        },
        fonts: { '"Poppins", sans-serif': { url: 'https://fonts.googleapis.com/css?family=Poppins', active: true } },
      },
    };

    uploadWidget = (window as any).cloudinary.createUploadWidget(widgetParams, (err: any, result: any) => {
      // TODO: find a way to return a promise on success and upload to the BE. and then close the uploader
      if (!result || result.event !== 'success') return;
      const { public_id: publicId, path, secure_url: url, thumbnail_url: thumbnailUrl } = result.info;
      // TODO: have a callback function here dynamically - varies for different uploads
      // if multiple, one image will be returned at a time - update BE
      postOrganization({ id: currentOrganizationId, input: { logo: { publicId, path, url, thumbnailUrl } } });
    });
  };

  const openUploadWidget = () => uploadWidget.open();

  return {
    uploadWidget,
    setupUploadWidget,
    openUploadWidget,
  };
};

export { cloudinarySignRequestPost };

interface ICloudinarySignRequestPost {
  callback: any;
  paramsToSign: any;
}
interface IFileUpload {
  folder: string;
  multiple?: boolean;
  cropping?: boolean;
  currentOrganizationId: string;
}
