import Vue from 'vue';
import Router from 'vue-router';
import config from '@/config';
import { get } from '@/utils/sharedMethods';
// not grouped
import Auth from './views/Auth.vue';
import Dashboard from './views/Dashboard.vue';
import GetStarted from './views/GetStarted.vue';
// core
import Organizations from './views/core/organizations/Organizations.vue';
import OrganizationsNew from './views/core/organizations/OrganizationsNew.vue';
// reports
import ReportPayments from './views/reports/ReportPayments.vue';
import ReportProfitAndLoss from './views/reports/ReportProfitAndLoss.vue';
import ReportSalesByCustomer from './views/reports/ReportSalesByCustomer.vue';
import ReportIncomeSummary from './views/reports/ReportIncomeSummary.vue';
import ReportPurchasesByVendor from './views/reports/ReportPurchasesByVendor.vue';
import ReportExpenseDetails from './views/reports/ReportExpenseDetails.vue';
import ReportInventorySummary from './views/reports/ReportInventorySummary.vue';
// crm
import Accounts from './views/crm/Accounts.vue';
import AccountsEdit from './views/crm/AccountsEdit.vue';
import AccountsView from './views/crm/AccountsView.vue';
// sales
import Estimates from './views/sales/Estimates.vue';
import EstimatesEdit from './views/sales/EstimatesEdit.vue';
import EstimatesView from './views/sales/EstimatesView.vue';
import SalesOrders from './views/sales/SalesOrders.vue';
import SalesOrdersEdit from './views/sales/SalesOrdersEdit.vue';
import SalesOrdersView from './views/sales/SalesOrdersView.vue';
import Invoices from './views/sales/Invoices.vue';
import InvoicesView from './views/sales/InvoicesView.vue';
import InvoicesEdit from './views/sales/InvoicesEdit.vue';
import CreditNotes from './views/sales/CreditNotes.vue';
import CreditNotesView from './views/sales/CreditNotesView.vue';
import CreditNotesEdit from './views/sales/CreditNotesEdit.vue';
// purchases
import PurchaseOrders from './views/purchases/PurchaseOrders.vue';
import PurchaseOrdersView from './views/purchases/PurchaseOrdersView.vue';
import PurchaseOrdersEdit from './views/purchases/PurchaseOrdersEdit.vue';
import Bills from './views/purchases/Bills.vue';
import BillsView from './views/purchases/BillsView.vue';
import BillsEdit from './views/purchases/BillsEdit.vue';
import VendorCredits from './views/purchases/VendorCredits.vue';
import VendorCreditsView from './views/purchases/VendorCreditsView.vue';
import VendorCreditsEdit from './views/purchases/VendorCreditsEdit.vue';
// inventory
import ItemsGroups from './views/inventory/ItemsGroups.vue';
import ItemsGroupsEdit from './views/inventory/ItemsGroupsEdit.vue';
import ItemsGroupsView from './views/inventory/ItemsGroupsView.vue';
import Items from './views/inventory/Items.vue';
import ItemsEdit from './views/inventory/ItemsEdit.vue';
import ItemsView from './views/inventory/ItemsView.vue';
import ItemAdjustments from './views/inventory/ItemAdjustments.vue';
import ItemAdjustmentsEdit from './views/inventory/ItemAdjustmentsEdit.vue';
import ItemAdjustmentsView from './views/inventory/ItemAdjustmentsView.vue';
// accounting
import Coas from './views/accounting/Coas.vue';
import Payments from './views/accounting/Payments.vue';
import PaymentsEdit from './views/accounting/PaymentsEdit.vue';
import PaymentsView from './views/accounting/PaymentsView.vue';
import Expenses from './views/accounting/Expenses.vue';
import ExpensesView from './views/accounting/ExpensesView.vue';
import ExpensesEdit from './views/accounting/ExpensesEdit.vue';
// settings
import Settings from './views/Settings.vue';

Vue.use(Router);

const routes: any = [
  { name: 'Dashboard', component: Dashboard, path: '/' },
  { name: 'Auth', component: Auth, path: '/auth' },
  { name: 'GetStarted', component: Dashboard, path: '/get-started' },
  // reports
  { name: 'ReportSalesByCustomer', component: ReportSalesByCustomer, path: '/reports/sales-by-customer' },
  { name: 'ReportReceivableSummary', component: ReportIncomeSummary, path: '/reports/receivable-summary' },
  { name: 'ReportsPaymentsReceived', component: ReportPayments, path: '/reports/payments-received' },
  { name: 'ReportPurchasesByVendor', component: ReportPurchasesByVendor, path: '/reports/purchases-by-vendor' },
  { name: 'ReportPayableSummary', component: ReportIncomeSummary, path: '/reports/payable-summary' },
  { name: 'ReportExpenseDetails', component: ReportExpenseDetails, path: '/reports/expense-details' },
  { name: 'ReportPaymentsMade', component: ReportPayments, path: '/reports/payments-made' },
  { name: 'ReportProfitAndLoss', component: ReportProfitAndLoss, path: '/reports/profit-and-loss' },
  { name: 'ReportInventorySummary', component: ReportInventorySummary, path: '/reports/inventory-summary' },
  // core
  { name: 'Organizations', component: Organizations, path: '/organizations' },
  { name: 'OrganizationsNew', component: OrganizationsNew, path: '/organizations/new' },
  { name: 'Invitation', component: Dashboard, path: '/invitation/:token?', props: true },
  // sales
  // order InvoicesEdit before InvoicesView - it is affecting component rendered
  { name: 'Customers', component: Accounts, path: '/sales/customers' },
  { name: 'CustomersEdit', component: AccountsEdit, path: '/sales/customers/edit/:itemId?', props: true },
  { name: 'CustomersView', component: AccountsView, path: '/sales/customers/:itemId?', props: true },
  { name: 'Estimates', component: Estimates, path: '/sales/estimates' },
  { name: 'EstimatesEdit', component: EstimatesEdit, path: '/sales/estimates/edit/:itemId?', props: true },
  { name: 'EstimatesView', component: EstimatesView, path: '/sales/estimates/:itemId', props: true },
  { name: 'SalesOrders', component: SalesOrders, path: '/sales/sales-orders' },
  { name: 'SalesOrdersEdit', component: SalesOrdersEdit, path: '/sales/sales-orders/edit/:itemId?', props: true },
  { name: 'SalesOrdersView', component: SalesOrdersView, path: '/sales/sales-orders/:itemId', props: true },
  { name: 'Invoices', component: Invoices, path: '/sales/invoices' },
  { name: 'InvoicesEdit', component: InvoicesEdit, path: '/sales/invoices/edit/:itemId?', props: true },
  { name: 'InvoicesView', component: InvoicesView, path: '/sales/invoices/:itemId', props: true },
  { name: 'PaymentsReceived', component: Payments, path: '/sales/payments-received' },
  { name: 'PaymentsReceivedEdit', component: PaymentsEdit, path: '/sales/payments-received/edit/:itemId?', props: true },
  { name: 'PaymentsReceivedView', component: PaymentsView, path: '/sales/payments-received/:itemId?', props: true },
  { name: 'CreditNotes', component: CreditNotes, path: '/sales/credit-notes' },
  { name: 'CreditNotesEdit', component: CreditNotesEdit, path: '/sales/credit-notes/edit/:itemId?', props: true },
  { name: 'CreditNotesView', component: CreditNotesView, path: '/sales/credit-notes/:itemId', props: true },
  // Purchases
  { name: 'Vendors', component: Accounts, path: '/purchases/vendors' },
  { name: 'VendorsEdit', component: AccountsEdit, path: '/purchases/vendors/edit/:itemId?', props: true },
  { name: 'VendorsView', component: AccountsView, path: '/sales/vendors/:itemId?', props: true },
  { name: 'PurchaseOrders', component: PurchaseOrders, path: '/purchases/purchase-orders' },
  { name: 'PurchaseOrdersEdit', component: PurchaseOrdersEdit, path: '/purchases/purchase-orders/edit/:itemId?', props: true },
  { name: 'PurchaseOrdersView', component: PurchaseOrdersView, path: '/purchases/purchase-orders/:itemId', props: true },
  { name: 'Bills', component: Bills, path: '/purchases/bills' },
  { name: 'BillsEdit', component: BillsEdit, path: '/purchases/bills/edit/:itemId?', props: true },
  { name: 'BillsView', component: BillsView, path: '/purchases/bills/:itemId', props: true },
  { name: 'PaymentsMade', component: Payments, path: '/sales/payments-made' },
  { name: 'PaymentsMadeEdit', component: PaymentsEdit, path: '/sales/payments-made/edit/:itemId?', props: true },
  { name: 'PaymentsMadeView', component: PaymentsView, path: '/sales/payments-made/:itemId?', props: true },
  { name: 'VendorCredits', component: VendorCredits, path: '/purchases/vendor-credits' },
  { name: 'VendorCreditsEdit', component: VendorCreditsEdit, path: '/purchases/vendor-credits/edit/:itemId?', props: true },
  { name: 'VendorCreditsView', component: VendorCreditsView, path: '/purchases/vendor-credits/:itemId', props: true },
  // inventory
  { name: 'ItemsGroupsEdit', component: ItemsGroupsEdit, path: '/inventory/groups/edit/:itemId?', props: true },
  { name: 'ItemsGroupsView', component: ItemsGroupsView, path: '/inventory/groups/:itemId', props: true },
  { name: 'ItemsGroups', component: ItemsGroups, path: '/inventory/groups' },
  { name: 'Items', component: Items, path: '/inventory/items' },
  { name: 'ItemsEdit', component: ItemsEdit, path: '/inventory/items/edit/:itemId?', props: true },
  { name: 'ItemsView', component: ItemsView, path: '/sales/items/:itemId', props: true },
  { name: 'CompositeItems', component: Items, path: '/inventory/composite-items' },
  { name: 'CompositeItemsEdit', component: ItemsEdit, path: '/inventory/composite-items/edit/:itemId?', props: true },
  { name: 'CompositeItemsView', component: ItemsView, path: '/inventory/composite-items/:itemId', props: true },
  { name: 'ItemAdjustments', component: ItemAdjustments, path: '/inventory/adjustments' },
  { name: 'ItemAdjustmentsEdit', component: ItemAdjustmentsEdit, path: '/inventory/adjustments/edit/:itemId?', props: true },
  { name: 'ItemAdjustmentsView', component: ItemAdjustmentsView, path: '/inventory/adjustments/:itemId', props: true },
  // accounting
  { name: 'Coas', component: Coas, path: '/accounting/coas/:coaId?', props: true },
  { name: 'Expenses', component: Expenses, path: '/accounting/expenses' },
  { name: 'ExpensesEdit', component: ExpensesEdit, path: '/accounting/expenses/edit/:itemId?', props: true },
  { name: 'ExpensesView', component: ExpensesView, path: '/accounting/expenses/:itemId', props: true },
  // settings
  { name: 'Settings', component: Settings, path: '/settings/:settingsGroup?/:settingsTab?', props: true },
  // Error pages
  // { name: 'error404', component: Error404, path: '/404' },
  // { name: 'error500', component: Error500, path: '/500' },
  // catch-all fallback route-in case of an error (find how to configure depending on the error code)
  { path: '*', redirect: '/404' },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
});

const accessPermissions: any = {
  // settings - redo these - to be per page
  Settings: 'settings.setting.manageUsers',
  // SettingsSystem: 'settings.setting.manageUsers',
  // SettingsCrm: 'settings.setting.preferences',
  // SettingsSales: 'settings.setting.preferences',
  // SettingsPurchases: 'settings.setting.preferences',
  // SettingsInventory: 'settings.setting.preferences',
  // SettingsAccounting: 'settings.setting.preferences',
  // reports
  ReportSalesByCustomer: 'reports.salesReports.salesByCustomer.canAccess',
  ReportReceivableSummary: 'reports.receivables.receivableSummary.canAccess',
  ReportsPaymentsReceived: 'reports.paymentsReceived.customerPayments.canAccess',
  ReportPurchasesByVendor: 'reports.purchasesAndExpenses.purchasesByVendor.canAccess',
  ReportPayableSummary: 'reports.payables.payableSummary.canAccess',
  ReportExpenseDetails: 'reports.purchasesAndExpenses.expenseDetails.canAccess',
  ReportPaymentsMade: 'reports.payables.vendorPayments.canAccess',
  ReportProfitAndLoss: 'reports.businessOverView.profitAndLoss.canAccess',
  ReportInventorySummary: 'reports.inventory.inventoryStockDetails.canAccess',
  // sales
  PaymentsReceived: 'sales.customerPayment.canView',
  PaymentsReceivedEdit: 'sales.customerPayment.canEdit',
  PaymentsReceivedView: 'sales.customerPayment.canView',
  // purchases
  Vendors: 'contacts.vendor.canView',
  VendorsEdit: 'contacts.vendor.canEdit',
  VendorsView: 'contacts.vendor.canView',
  Expenses: 'purchases.expense.canView',
  ExpensesEdit: 'purchases.expense.canEdit',
  ExpensesView: 'purchases.expense.canView',
  PurchaseOrders: 'purchases.purchaseOrder.canView',
  PurchaseOrdersEdit: 'purchases.purchaseOrder.canEdit',
  PurchaseOrdersView: 'purchases.purchaseOrder.canView',
  Bills: 'purchases.bill.canView',
  BillsEdit: 'purchases.bill.canEdit',
  BillsView: 'purchases.bill.canView',
  PaymentsMade: 'purchases.vendorPayment.canView',
  PaymentsMadeEdit: 'purchases.vendorPayment.canEdit',
  PaymentsMadeView: 'purchases.vendorPayment.canView',
  VendorCredits: 'purchases.vendorCredit.canView',
  VendorCreditsEdit: 'purchases.vendorCredit.canEdit',
  VendorCreditsView: 'purchases.vendorCredit.canView',
  // inventory
  // ItemsGroups: 'items.item.canView',
  // ItemsGroupsEdit: 'items.item.canEdit',
  // ItemsGroupsView: 'items.item.canEdit',
  Items: 'items.item.canView',
  ItemsEdit: 'items.item.canEdit',
  ItemsView: 'items.item.canEdit',
  CompositeItems: 'items.compositeItem.canView',
  CompositeItemsEdit: 'items.compositeItem.canEdit',
  CompositeItemsView: 'items.compositeItem.canEdit',
  Adjustments: 'items.inventoryAdjustment.canView',
  ItemAdjustmentsEdit: 'items.inventoryAdjustment.canEdit',
  ItemAdjustmentsView: 'items.inventoryAdjustment.canEdit',
};

// guard clause
router.beforeEach((to: any, from, next) => {
  const isAuthenticated = !!(localStorage.getItem('token') || '');
  const unprotectedRoutes = ['Auth'];
  const accessPermitted = accessPermissions[to.name] ? get(router.app?.currentAccessRole, accessPermissions[to.name], false) : true;

  if (!isAuthenticated && !unprotectedRoutes.includes(to.name)) {
    Vue.prototype.$toastr({ type: 'error', msg: 'Log in or Sign up before you can performing this action!' });
    next({ name: 'Auth' });
  } else if (isAuthenticated && unprotectedRoutes.includes(to.name)) {
    Vue.prototype.$toastr({ type: 'error', msg: 'Sign Out first, then try again.' });
    next({ name: 'Dashboard' });
  } else if (config.environment === 'production' && isAuthenticated && !accessPermitted) {
    Vue.prototype.$toastr({ type: 'error', msg: "You don't have permission to perform this operation. Please contact your Administrator." });
    // next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;

// vue 3
// import { createRouter, createWebHistory } from 'vue-router';

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
//   scrollBehavior(to: any, from: any, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     } else {
//       return { top: 0 };
//     }
//   },
// });

// // guard clause
// router.beforeEach((to: any, from, next) => {
//   const isAuthenticated = !!(localStorage.getItem('token') || '');
//   const unprotectedRoutes = ['Auth'];
//   // TODO: Make these notifications work
//   if (!isAuthenticated && !unprotectedRoutes.includes(to.name)) {
//     // Vue.prototype.$toastr({ type: 'error', msg: 'Log in or Sign up before you can performing this action!' });
//     next({ name: 'Auth' });
//   } else if (isAuthenticated && unprotectedRoutes.includes(to.name)) {
//     // Vue.prototype.$toastr({ type: 'error', msg: 'Sign Out first, then try again.' });
//     next({ name: 'Dashboard' });
//   } else {
//     next();
//   }
// });
