import { computed, getCurrentInstance } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import {
  AccessRole,
  AccessRoleFull,
  AccessRoleCurrent,
  AccessRoles,
  AccessRoleAdd,
  AccessRoleUpdate,
  AccessRoleDelete,
} from '@/graphql/core/accessRole.gql';
import { handleError } from '@/composables/useErrorHandler';
import { get } from '@/utils/sharedMethods';
// types
import { IPager } from '@/types/others/shared';
// remove
import Vue from 'vue';

export const checkPermission = (paths: string[]) => {
  const currentAccessRole: any = computed(() => getCurrentInstance()?.root?.data?.currentAccessRole);
  if (currentAccessRole.value?.isOwner) return true;

  return paths.some((path: string) => get(currentAccessRole.value, path, false));
};

// Queries
const AccessRoleFetch = ({ accessRoleId, queryType = 'default', noCache = false }: IAccessRoleAPI) => {
  const queryMap: any = { default: AccessRole, full: AccessRoleFull, current: AccessRoleCurrent };
  const {
    result,
    onResult: onAccessRoleResult,
    loading,
    error,
  } = useQuery(queryMap[queryType], { id: accessRoleId }, { clientId: 'core', fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const accessRole: any = computed(() => result.value?.accessRole ?? {});
  const accessRoleNameMap: any = computed(() => result.value?.accessRoleNameMap ?? {});
  return { accessRole, accessRoleNameMap, onAccessRoleResult, loading, error };
};

const AccessRolesFetch = (variables: IAccessRoleAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: accessRoleRefetch,
    error,
  } = useQuery(AccessRoles, variables, { clientId: 'core', fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const accessRoles = computed(() => result.value?.accessRoles ?? []);
  return { accessRoles, loading, accessRoleRefetch, error };
};

// mutations
const AccessRolePost = ({ data, mutationType = 'add' }: IAccessRoleAPI) => {
  const mutationMap: any = {
    add: { mutation: AccessRoleAdd, message: 'added' },
    update: { mutation: AccessRoleUpdate, message: 'updated' },
    delete: { mutation: AccessRoleDelete, message: 'deleted' },
  };
  const {
    mutate: postAccessRole,
    loading,
    onDone,
    onError,
  } = useMutation(mutationMap[mutationType].mutation, { clientId: 'core', throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `Access role successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert' }));

  return { postAccessRole, loading };
};

export { AccessRoleFetch, AccessRolesFetch, AccessRolePost };

interface IFilter {
  filterBy: string[];
  search?: string;
}
export interface IAccessRoleAPI {
  organizationId?: string;
  accessRoleId?: string;
  pager?: IPager;
  filter?: IFilter;
  data?: any;
  queryType?: string;
  noCache?: boolean;
  mutationType?: string;
}
