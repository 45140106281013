// = = Import all js file functions
import $ from 'jquery';

// tslint:disable:no-var-requires
export const mUtil = require('./base/util.js').default;
export const mApp = require('./base/app.js').default;
const mLayout = require('./base/layout.js').default;
export const { datePicker, mPlugins } = require('./base/plugins.js');
export const mWizard = require('./components/wizard.js').default;
// const mQuickSidebar = require('./base/quick-sidebar.js').default;
// const HeaderActions = require('./components/header-actions.js');
// const Dashboard = require('./pages/dashboard.js');

export const mTable = () => {
  const tableJs = require('./components/table').default;
  $(() => {
    tableJs.init();
  });
};

export default function () {
  $(() => {
    mUtil.init();
    mApp.init();
    mLayout.init();
    mPlugins.init();
    // mQuickSidebar.init();
  });
}
