// types
import { IStatusesDocuments } from '@/types/others/shared';

// tslint:disable:no-var-requires
const countriesJson = require('./countries.json');
export const countries = Object.keys(countriesJson).map((key) => ({ id: key, ...countriesJson[key] }));
const currenciesJson = require('./currencies.json');
export const currencies = Object.values(currenciesJson);
export const timezonesJson = require('./timezones.json');
export const timezones = Object.keys(timezonesJson).map((key) => ({ id: key, name: timezonesJson[key] }));
export const industries = require('./industries.json');

export const languages = [{ id: 'en', name: 'English' }];

export const fiscalYears = [
  'January - December',
  'February - January',
  'March - February',
  'April - March',
  'May - April',
  'June - May',
  'July - June',
  'August - July',
  'September - August',
  'October - September',
  'November - October',
  'December - November',
];

export const salutations = [
  { id: 'mr', name: 'Mr.' },
  { id: 'mrs', name: 'Mrs.' },
  { id: 'mis', name: 'Miss.' },
  { id: 'dr', name: 'Dr.' },
];

// system settings
export const decimalPlaces = [0, 2, 3];
export const numberFormats = [
  { id: { thousand: ',', mark: '.' }, name: '1,234,567.89' },
  { id: { thousand: ' ', mark: ',' }, name: '1 234 567,89' },
  { id: { thousand: '.', mark: ',' }, name: '1.234.567,89' },
];
const longDateFormats = ['dd MMM yyyy', 'dd MMMM yyyy', 'MMMM dd, yyyy', 'EEE, MMMM dd, yyyy', 'EEEEEE, MMMM dd, yyyy', 'MMM dd, yyyy', 'yyyy MM dd'];
export const dateFormats = {
  '/': {
    short: ['MM/dd/yy', 'dd/MM/yy', 'yy/MM/dd'],
    medium: ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd'],
    long: longDateFormats,
  },
  '-': {
    short: ['MM-dd-yy', 'dd-MM-yy', 'yy-MM-dd'],
    medium: ['MM-dd-yyyy', 'dd-MM-yyyy', 'yyyy-MM-dd'],
    long: longDateFormats,
  },
  '.': {
    short: ['MM.dd.yy', 'dd.MM.yy', 'yy.MM.dd'],
    medium: ['MM.dd.yyyy', 'dd.MM.yyyy', 'yyyy.MM.dd'],
    long: longDateFormats,
  },
};
export const paymentModeTypes = [
  { id: 'cash', name: 'Cash' },
  { id: 'mpesa', name: 'Mpesa' },
  { id: 'credit', name: 'Credit' },
  { id: 'bank', name: 'Bank' },
];
export const statusDocuments: IStatusesDocuments = {
  // accounting
  accounts: [
    { id: '', name: 'All Accounts' },
    { id: 'active', name: 'Active', color: 'success' },
    { id: 'inactive', name: 'Inactive', color: 'metal' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
    { id: 'asset', name: 'Asset Accounts' },
    { id: 'liability', name: 'Liability Accounts' },
    { id: 'equity', name: 'Equity Accounts' },
    { id: 'income', name: 'Income Accounts' },
    { id: 'expense', name: 'Expense Accounts' },
  ],
  expenses: [
    { id: '', name: 'All Expenses' },
    { id: 'active', name: 'Active', color: 'metal' },
    { id: 'inactive', name: 'Inactive', color: 'metal' },
    { id: 'nonBillable', name: 'Non-Billable', color: 'default' },
    { id: 'unbilled', name: 'Unbilled', color: 'default' },
    { id: 'invoiced', name: 'Invoiced', color: 'metal' },
    { id: 'reimbursed', name: 'Reimbursed', color: 'default' },
    { id: 'active', name: 'Active', color: 'default' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  // Customers & Vendors
  customers: [
    { id: '', name: 'All Customers' },
    { id: 'active', name: 'Active', color: 'success' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
    { id: 'crmCustomers', name: 'CRM Customers' },
    { id: 'duplicateCustomers', name: 'Duplicate' },
    { id: 'inactive', name: 'Inactive', color: 'metal' },
    { id: 'overdueCustomers', name: 'Overdue' },
    { id: 'unpaidCustomers', name: 'Unpaid' },
    { id: 'creditLimitExceeded', name: 'Credit Limit Exceeded' },
  ],
  vendors: [
    { id: '', name: 'All Vendors' },
    { id: 'active', name: 'Active', color: 'success' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
    { id: 'vendorsROI', name: 'Vendors - Reorder Items' },
    { id: 'crmVendors', name: 'CRM Vendors' },
    { id: 'inactive', name: 'Inactive', color: 'metal' },
    { id: 'duplicateVendors', name: 'Duplicate' },
  ],
  // inventory
  items: [
    { id: '', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'lowStock', name: 'Low Stock' },
    { id: 'sales', name: 'Sales' },
    { id: 'purchases', name: 'Purchases' },
    { id: 'inventory', name: 'Inventory' },
    { id: 'nonInventory', name: 'Non Inventory' },
    { id: 'services', name: 'Services' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'returnable', name: 'Returnable' },
    { id: 'nonReturnable', name: 'Non Returnable' },
    // { id: 'composite', name: 'Composite' },
    // { id: 'nonComposite', name: 'Non Composite' },
    { id: 'grouped', name: 'Grouped' },
    { id: 'unGrouped', name: 'Un Grouped' },
  ],
  itemsGroups: [
    { id: '', name: 'All' },
    { id: 'active', name: 'Active', color: 'success' },
    { id: 'inactive', name: 'Inactive', color: 'metal' },
  ],
  itemAdjustments: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'adjusted', name: 'Adjusted', color: 'success' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],

  // sales documents
  estimates: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'sent', name: 'Sent', color: 'success' },
    { id: 'viewed', name: 'Viewed', color: 'secondary' },
    { id: 'accepted', name: 'Accepted', color: 'default' },
    { id: 'declined', name: 'Declined', color: 'default' },
    { id: 'invoiced', name: 'Invoiced', color: 'default' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  salesOrders: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'confirmed', name: 'Confirmed', color: 'success' },
    { id: 'closed', name: 'Closed', color: 'secondary' },
    { id: 'void', name: 'Void', color: 'secondary' },
    { id: 'onHold', name: 'OnHold', color: 'warning' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  invoices: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'sent', name: 'Sent', color: 'success' },
    { id: 'due', name: 'Due', color: 'info' },
    { id: 'overdue', name: 'Overdue', color: 'warning' },
    { id: 'partiallyPaid', name: 'Partially Paid', color: 'info' },
    { id: 'paid', name: 'Paid', color: 'brand' },
    { id: 'void', name: 'Void', color: 'secondary' },
    { id: 'writtenOff', name: 'Written Off', color: 'secondary' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  creditNotes: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'open', name: 'Open', color: 'warning' },
    { id: 'closed', name: 'Closed', color: 'success' },
    { id: 'void', name: 'Void', color: 'secondary' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  payments: [
    { id: '', name: 'All' },
    { id: 'pending', name: 'Pending', color: 'metal' },
    { id: 'success', name: 'Success', color: 'success' },
    { id: 'failed', name: 'Failed', color: 'warning' },
    { id: 'void', name: 'Void', color: 'secondary' },
  ],

  // purchase documents
  purchaseOrders: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'open', name: 'Confirmed', color: 'success' },
    { id: 'closed', name: 'Closed', color: 'brand' },
    { id: 'cancelled', name: 'Cancelled', color: 'secondary' },
    { id: 'void', name: 'Void', color: 'secondary' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  bills: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'open', name: 'Open', color: 'success' },
    { id: 'due', name: 'Due', color: 'info' },
    { id: 'overdue', name: 'Overdue', color: 'warning' },
    { id: 'partiallyPaid', name: 'Partially Paid', color: 'info' },
    { id: 'paid', name: 'Paid', color: 'brand' },
    { id: 'void', name: 'Void', color: 'secondary' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  vendorCredits: [
    { id: '', name: 'All' },
    { id: 'draft', name: 'Draft', color: 'metal' },
    { id: 'open', name: 'Open', color: 'warning' },
    { id: 'closed', name: 'Closed', color: 'success' },
    { id: 'void', name: 'Void', color: 'secondary' },
    { id: 'deleted', name: 'Deleted', color: 'default' },
  ],
  // paymentsMade: [
  //   { id: '', name: 'All' },
  //   { id: 'pending', name: 'Pending', color: 'metal' },
  //   { id: 'success', name: 'Success', color: 'success' },
  //   { id: 'failed', name: 'Failed', color: 'warning' },
  //   { id: 'void', name: 'Void', color: 'secondary' },
  // ],
};

// Modals Data
// TODO: move this to an external file
export const AlertData: ISharedModalData = {
  modal: 'Alert',
  size: 'sm',
  hideHeader: true,
  bodyClass: 'm--padding-10',
};
export const OrgUserFormData: ISharedModalData = {
  modal: 'OrgUserForm',
  title: 'Update User',
};
export const UserInviteFormData: ISharedModalData = {
  modal: 'UserInviteForm',
  title: 'Invite User',
};
export const AccessRoleFormData: ISharedModalData = {
  modal: 'AccessRoleForm',
  size: 'lg',
};
export const CurrenciesFormData: ISharedModalData = {
  modal: 'CurrenciesForm',
  title: 'Manage Currency',
};
export const TaxesFormData: ISharedModalData = {
  modal: 'TaxesForm',
  title: 'Manage Tax',
};
export const CustomerPaymentsFormData: ISharedModalData = {
  modal: 'PaymentModesForm',
  title: 'Manage Payment Mode',
  size: 'lg',
};
export const CurrenciesChangeBaseData: ISharedModalData = {
  modal: 'CurrenciesChangeBase',
  title: 'Change Base Currency',
};
export const OrganizationCloneData: ISharedModalData = {
  modal: 'OrganizationClone',
  title: 'Clone Organization',
};
export const OrganizationCloneAskData: ISharedModalData = {
  modal: 'OrganizationCloneAsk',
  hideHeader: true,
  bodyClass: 'm--padding-10',
};
export const NonBaseCurrencyWarningData: ISharedModalData = {
  modal: 'NonBaseCurrencyWarning',
  title: 'Please take care of the following',
};
export const ConfigDocumentNoData: ISharedModalData = {
  modal: 'ConfigDocumentNo',
};
export const InvoiceCheckoutData: ISharedModalData = {
  modal: 'InvoiceCheckout',
  title: 'Checkout',
};
export const ApplyCreditsFromInvoiceData: ISharedModalData = {
  modal: 'ApplyCreditsFromInvoice',
  size: 'xl',
};
export const ApplyCreditsFromCreditNoteData: ISharedModalData = {
  modal: 'ApplyCreditsFromCreditNote',
  size: 'xl',
};
export const RefundCreditBalanceData: ISharedModalData = {
  modal: 'RefundCreditBalance',
  size: 'lg',
};
export const BundleItemsModalData: ISharedModalData = {
  modal: 'BundleItemsModal',
  size: 'lg',
  title: 'New Items Bundle',
};
export const UnbundleItemsModalData: ISharedModalData = {
  modal: 'UnbundleItemsModal',
  size: 'lg',
  title: 'Unbundle Items Bundle',
};
export const BundleItemsViewModalData: ISharedModalData = {
  modal: 'BundleItemsViewModal',
  size: 'lg',
  bodyClass: 'px-0',
};

// Types
interface ISharedModalData {
  modal: string;
  title?: string;
  size?: string;
  hideHeader?: boolean;
  bodyClass?: string;
  hideOkButton?: boolean;
  hideCancelButton?: boolean;
  okButtonText?: string;
  cancelButtonText?: string;
  cancelButtonClass?: string;
}
