<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    chartName: { type: String, required: true },
    chartdata: { type: Object, default: null },
    options: { type: Object, default: null },
  },
  data() {
    return {
      chartOptions: {
        salesChart: {
          title: {
            display: false,
          },
          tooltips: {
            intersect: false,
            mode: 'nearest',
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10,
          },
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: 'index',
          },
          scales: {
            xAxes: [
              {
                display: false,
                gridLines: false,
                scaleLabel: {
                  display: true,
                  labelString: 'Month',
                },
              },
            ],
            yAxes: [
              {
                display: false,
                gridLines: false,
                scaleLabel: {
                  display: true,
                  labelString: 'Value',
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          elements: {
            line: {
              tension: 0.19,
            },
            point: {
              radius: 4,
              borderWidth: 12,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 5,
              bottom: 0,
            },
          },
        },
        financeChart: {
          title: {
            display: false,
          },
          tooltips: {
            mode: 'nearest',
            intersect: false,
            position: 'nearest',
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10,
          },
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
                gridLines: false,
                scaleLabel: {
                  display: true,
                  labelString: 'Month',
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: false,
                gridLines: false,
                scaleLabel: {
                  display: true,
                  labelString: 'Value',
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          elements: {
            line: {
              tension: 0.0000001,
            },
            point: {
              radius: 4,
              borderWidth: 12,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 0,
            },
          },
        },
      },
    };
  },
  mounted() {
    // find a way to do it - and limit to the needed chart
    if (this.chartName === 'salesChart') {
      const gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 240);
      gradient.addColorStop(0, Chart.helpers.color('#00c5dc').alpha(0.7).rgbString());
      gradient.addColorStop(1, Chart.helpers.color('#f2feff').alpha(0).rgbString());
      this.chartdata.datasets[0].backgroundColor = gradient;
    }

    this.renderChart(this.chartdata, this.chartOptions[this.chartName]);
  },
};
</script>
