import { computed } from '@vue/composition-api';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { ReportInventorySummary } from '@/graphql/reports/inventoryReports.gql';
import { handleError } from '@/composables/useErrorHandler';
// types
import { IPager } from '@/types/others/shared';

const ReportSalesByCustomerFetch = ({ organizationId, filter }: IInventoryReportsAPI) => {
  const {
    result,
    onResult: onInvoiceResult,
    refetch: reportSalesByCustomerRefetch,
    loading,
    error,
  } = useQuery(ReportInventorySummary, { organizationId, filter });
  const reportInventorySummary: any = computed(() => result.value?.reportInventorySummary ?? {});
  const currencyFormat: any = computed(() => ({
    ...reportInventorySummary.value.currency?.format,
    prefix: reportInventorySummary.value.currency?.symbol,
  }));

  return { reportInventorySummary, currencyFormat, onInvoiceResult, reportSalesByCustomerRefetch, loading, error };
};

export { ReportSalesByCustomerFetch };

interface IInventoryReportsAPI {
  organizationId?: string;
  filter?: any;
}
