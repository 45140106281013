import { computed } from '@vue/composition-api';
import { useQuery } from '@vue/apollo-composable';
import { ReportPaymentsByAccount } from '@/graphql/reports/paymentsReports.gql';
// types
import { IPager } from '@/types/others/shared';

const ReportPaymentsFetch = ({ organizationId, pager, filter, reportType }: IPaymentsReportsAPI) => {
  const queryMap: any = { reportPaymentsByAccount: ReportPaymentsByAccount };
  const {
    result,
    onResult: onReportResult,
    refetch: reportRefetch,
    loading,
    error,
  } = useQuery(queryMap[reportType], { organizationId, pager, filter });
  const report: any = computed(() => result.value?.[reportType] ?? {});
  const currency: any = computed(() => report.value?.currency ?? {});
  const currencyFormat: any = computed(() => ({ ...currency.value?.format, prefix: currency.value?.symbol }));

  return { report, currencyFormat, onReportResult, reportRefetch, loading, error };
};

export { ReportPaymentsFetch };

interface IFilter {
  fromDate: string;
  toDate: string;
}
interface IPaymentsReportsAPI {
  organizationId?: string;
  pager?: IPager;
  filter?: IFilter;
  reportType: string;
}
