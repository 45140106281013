import { useQuery } from '@vue/apollo-composable';
import { Coas, CoasGrouped } from '@/graphql/accounting/coa.gql';
import { IFilter, IPager } from '@/types/others/shared';
import { computed } from 'vue-demi';

const CoasGroupedFetch = (variables: ICoaAPI) => {
  const { result, onResult: onCoasGroupedFetch, loading, error } = useQuery(CoasGrouped, variables);

  const coasGrouped = computed(() => result.value?.coasGrouped ?? []);
  return { coasGrouped, onCoasGroupedFetch, loading, error };
};

const coasFetch = (variables: ICoaAPI) => {
  const { result, loading, error, refetch: coasRefetch } = useQuery(Coas, variables);
  const coas = computed(() => result.value?.coas ?? []);
  return { coas, loading, error, coasRefetch };
};

export { CoasGroupedFetch, coasFetch };

interface ICoaAPI {
  organizationId: string;
  accountType?: string;
  groups?: string[];
  queryType?: string;
  filter?: IFilter;
  pager?: IPager;
}
