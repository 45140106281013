import { computed, Ref } from '@vue/composition-api';
import { handleError } from '@/composables/useErrorHandler';
import { Expense, ExpenseFull, Expenses, ExpenseView, ExpenseAdd, ExpenseUpdate, ExpenseDelete } from '@/graphql/accounting/expense.gql';
// types
import { IFilter, IPager } from '@/types/others/shared';
import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
// remove
import Vue from 'vue';

// queries
const expenseFetch = ({ expenseId, queryType = 'default', noCache = false }: IExpenseAPI) => {
  const queryMap: any = { default: Expense, full: ExpenseFull, view: ExpenseView };
  const {
    result,
    onResult: onExpenseResult,
    loading,
    refetch: expenseRefetch,
    error,
  } = useQuery(queryMap[queryType], { id: expenseId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const expense: any = computed(() => result.value?.expense ?? { currency: {} });
  return { expense, onExpenseResult, loading, expenseRefetch, error };
};

const expenseLazyFetch = ({ expenseId, queryType = 'default', noCache = false }: IExpenseLazyFetchAPI) => {
  const queryMap: any = { default: Expense, full: ExpenseFull, view: ExpenseView };
  const {
    result,
    onResult: onExpenseResult,
    load: expenseQueryLoad,
  } = useLazyQuery(queryMap[queryType], { id: expenseId }, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const expense = computed(() => result.value?.expense ?? {});
  return { expense, expenseQueryLoad, onExpenseResult };
};

const expensesFetch = (variables: IExpenseAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: expensesRefetch,
    onResult: onExpensesResult,
    error,
  } = useQuery(Expenses, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const expenses = computed(() => result.value?.expenses ?? []);
  return { expenses, loading, expensesRefetch, onExpensesResult, error };
};

const expensesLazyFetch = (variables: IExpenseAPI, noCache = false) => {
  const {
    result,
    loading,
    refetch: expensesRefetch,
    onResult: onExpensesResult,
    load: expensesQueryLoad,
    error,
  } = useLazyQuery(Expenses, variables, { fetchPolicy: noCache ? 'no-cache' : 'cache-first' });
  const expenses = computed(() => result.value?.expenses ?? []);
  return { expenses, loading, expensesRefetch, onExpensesResult, expensesQueryLoad, error };
};

// mutations
const expensePost = ({ mutationType = 'add', modalNotification = false }: IExpenseAPI) => {
  const mutationMap: any = {
    add: { mutation: ExpenseAdd, message: 'added' },
    update: { mutation: ExpenseUpdate, message: 'updated' },
    void: { mutation: ExpenseDelete, message: 'voided' },
  };
  const { mutate: postExpense, loading, onDone, onError } = useMutation(mutationMap[mutationType].mutation, { throws: 'always' });

  onDone((result: any) => Vue.prototype.$toastr({ msg: `Expense successfully ${mutationMap[mutationType].message}!` }));
  onError((error) => handleError({ error, apollo: true, type: 'formAlert', modalNotification }));

  return { postExpense, loading };
};

export { expenseFetch, expenseLazyFetch, expensesFetch, expensesLazyFetch, expensePost };

// types
interface IExpenseLazyFetchAPI {
  expenseId?: Ref<string | null>;
  queryType?: string;
  noCache?: boolean;
}
interface IExpenseFilter extends IFilter {
  accountId: string;
  withDueAmount: boolean;
}
interface IExpenseAPI {
  organizationId?: string;
  expenseId?: string;
  pager?: IPager;
  filter?: IExpenseFilter;
  queryType?: string;
  noCache?: boolean;
  // for post
  data?: any;
  mutationType?: string;
  modalNotification?: boolean;
}
