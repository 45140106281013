import { statusDocuments } from '@/utils/data';
import { ToCamelCase } from '@/utils/sharedMethods';
// types
import { IStatusDocument } from '@/types/others/shared';

const useDocumentStatuses = (documentName: string) => {
  const documentStatuses: IStatusDocument[] = statusDocuments[ToCamelCase(documentName)];

  const getStatusColor = (status: string) => {
    return documentStatuses?.find((s: any) => s.id === status)?.color;
  };

  return {
    documentStatuses,
    getStatusColor,
  };
};

export default useDocumentStatuses;
